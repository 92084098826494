
import logo2 from "@/assets/icons/icon-login.svg";
import logo from "@/assets/icons/icon-total.svg";
import iconMail from "@/assets/icons/ri_mail-line.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Spinner } from '@/components/Spinner';
import { recoverPassword } from "@/services";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
export interface RecoverPasswordInterface { }

const RecoverPassword: React.FC<RecoverPasswordInterface> = () => {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [isLoad, setIsLoad] = useState(false);
	const [type, setType] = useState<any>("error");
	const navigate = useNavigate();

	const setMessageComponent = (title: string, type: string) => {
		setMessage(title);
		setType(type);
		setTimeout(() => {
			setMessage("");
		}, 3000)
	}

	const sendEmail = async () => {
		if (email.length > 0) {
			setIsLoad(true);
			const resp = await recoverPassword(email);
			if (resp) {
				setMessageComponent("Se ha enviado un email a tu correo, Revisa tu bandeja de entrada", "done");
				setTimeout(()=>{
					navigate("/login");
				},3000);
			} else {
				setMessageComponent("No se pudo enviar el correo de recuperación", "error")
			}
			setIsLoad(false);
		} else {
			setMessageComponent("El email es obligatorio", "warning");
		}
	}
	return (
		<div className="ca__loginScreen">
			{
				message.length > 0 ? <MessageComponent title={message} type={type} /> : <></>
			}
			<div className="ca__loginScreen-container">
				<div>
					<img className="ca__login-iconletters" src={logo2} alt="Copy assistant" />
					<img className="ca__login-icon" src={logo} alt="Copy assistant" />
					<p>Genera copys que convierten en cuestión de segundos con inteligencia artificial.</p>
					{/* <img className="ca__login-pc" src={iconPc} alt="pc" /> */}
				</div>
				<div>
					<div className="ca__loginScreen-container-info">
						<h4 className="ca__recover-title">
							Recuperar Contraseña
						</h4>
						<p className="ca__recover-info">Te enviaremos instrucciones a tu correo electrónico de registro para restablecer tu contraseña.</p>
						<div className="ca__recover-inputs">
							<label htmlFor="email">
								Correo
							</label>
							<div>
								<img src={iconMail} alt="Password" />
								<input
									type="email"
									name="email"
									id="email"
									placeholder="Tu correo"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div
							className="ca__loginScreen-btnLogin"
							onClickCapture={sendEmail}
						>
							{isLoad ? (
								<Spinner cssClass="" />
							) : (
								"Enviar"
							)}
						</div>
						<p
							className="ca__recoverPass-volver"
							onClickCapture={() => navigate("/login", { replace: true })}
						>
							Regresar al login
						</p>
					</div>
				</div>
			</div>
		</div>

	)
};

export default RecoverPassword;
