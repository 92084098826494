import { AppStore, IHistory } from '@/models';
import { updateCopyFetch } from "@/services";
import { changeFavoriteChoice, deleteChoice, getHistoryFetch } from '@/services/projects.service';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const useEditorComponent = () => {
    const content = useSelector((store: AppStore) => store.projects.currentContent);

    const [copy, setCopy] = useState("");
    const [message, setMessage] = useState("");
    const [typeMessage, setTypeMessage] = useState<"error" | "warning" | "info" | "done">("done");
    const [history, sethistory] = useState<IHistory[]>([]);
    const [loadDelete, setLoadDelete] = useState(false);
    const [showFavorite, setShowFavorite] = useState(false);

    const project = useSelector((state: AppStore) => state.projects.currentProject);
    const token = useSelector((state: AppStore) => state.user.user?.accessToken);

    // const divRef = React.createRef<HTMLDivElement>();

    // const { showModal, openModalGenerate, changeModal, openModal, regenarate, isLoad } = useEditComponent({ content, setCurrentElement,handleSetHistory,showHistory });
    // const { regenarate, isLoad } = useEditComponent();


    const extractCopy = () => {
        if (content) {
            const { copy } = content;
            setCopy(copy ?? "No hay copy");
        }
    }

    // const handleOnChange = async (e: EditorChangeEvent) => {
    //   if (!e.html.includes("<img") && !e.html.includes("<video")) {
    //     setContent(e.html);
    //     let newCopy = e.html;
    //     // if (content?.type === "EMAIL") {
    //     //   newCopy = `<br/>Asunto: ${subject}<br/>${newCopy}`;
    //     // }
    //     const updatedElementApi = await updateCopyFetch(project!.id, content!.id, token!, newCopy);
    //     setUpdatedElement(updatedElementApi);

    //   }
    // }

    const handleOnChange = async (divRef: React.RefObject<HTMLDivElement>) => {
        let divContent: any = divRef.current?.innerHTML;
        if (!divContent.includes("<img") && !divContent.includes("<video")) {
            setMessage('Guardado..')

            setCopy(divContent);
            let newCopy = divContent;
            // if (content?.type === "EMAIL") {
            //   newCopy = `<br/>Asunto: ${subject}<br/>${newCopy}`;
            // }


            // const updatedElementApi = await updateCopyFetch(project!.id, content!.id, token!, newCopy);
            await updateCopyFetch(project!.id, content!.id, token!, newCopy);
            // setUpdatedElement(updatedElementApi);

            setTimeout(() => {
                setMessage('')
            }, 1000)
        }


    }

    const copyInClipboard = () => {
        setMessage("Copiado en portapapeles");
        setTypeMessage("done");
        setTimeout(() => {
            setMessage("");
        }, 2000)
    }

    const changeFavorite = async (id: string, favorite: boolean) => {
        const resp = await changeFavoriteChoice(project!.id, content!.id, token!, id, favorite);
        if (resp) {
            // var h = history.map((e)=>{
            //     console.log("h");
            //     console.log(e.id);
            //     return e.id === resp.id ? resp : e
            // });
            // console.log("h");
            // console.log(h);
            // sethistory(h);
            sethistory((h) => h.map((e) => e.id === resp.id ? resp : e))
        }else{
            setMessage("No se pudo agregar a favoritos. Intentalo más tarde");
            setTypeMessage("error");
            setTimeout(()=>{
                setMessage("");
            }, 3000 )
        }

    }
    const deleteChoiceFunction = async (id: string) => {
        setLoadDelete(true);
        const resp = await deleteChoice(project!.id, content!.id, token!, id);
        if(resp){
            sethistory((e)=> e.filter((value)=> value.id !== id));
            setMessage("Copy eliminado con éxito");
            setTypeMessage("done");
            setTimeout(()=>{
                setMessage("");
            }, 3000 )
        }else{
            setMessage("No se pudo eliminar el copy. Intentalo más tarde");
            setTypeMessage("error");
            setTimeout(()=>{
                setMessage("");
            }, 3000 )
        }
        setLoadDelete(false);

    }


    // const convertHtmltoPlain = (html: string) => {
    //     let element = document.createElement('div');

    //     element.innerHTML = html;
    //     return element.textContent || element.innerText || "";
    // }


    const startGetHistory = async () => {
        const resp = await getHistoryFetch(project!.id, content!.id, token!);
        if (resp) {
            sethistory(resp);
        } else {
            // !Mostrar alerta de error
        }
    }

    useEffect(() => {
        extractCopy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])


    useEffect(() => {
        if (project && content && token) startGetHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, content])

    return {
        message,
        typeMessage,
        copyInClipboard,
        copy,
        handleOnChange,
        history,
        changeFavorite,
        deleteChoiceFunction,
        loadDelete,
        showFavorite,
        setShowFavorite
    }
}