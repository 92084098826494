import { MailIcon } from "@/components/icons";
import AdSocialIcon from "@/components/icons/AdSocialIcon";
import GuionIcon from "@/components/icons/GuionIcon";
import PitchVentaIcon from "@/components/icons/PitchVentaIcon";
import PostSocialIcon from "@/components/icons/PostSocialIcon";
import SellPageIcon from "@/components/icons/SellPageIcon"; 
// import { EmailForm, GeneralFormStep2, SellPageForm } from "@/components/Modal/CreateElement/ElementStep2";

export const typesObjectives = [
    {
        name: "Email",
        value: "EMAIL",
    },
    {
        name: "AIDA Email",
        value: "AIDA_EMAIL",
    },
    {
        name: "Post Social media",
        value: "SOCIAL_POST",
    },
    {
        name: "Ad Social Media",
        value: "AD",
    },
    // {
    //     name: "Pitch de venta",
    //     value: "PITCH",
    //     step1: GeneralFormStep2
    // },
    {
        name: "Guión para Video",
        value: "VIDEO_SCRIPT",
    },
    {
        name: "Página de ventas",
        value: "LANDING_PAGE",
    },
    {
        name: "Contenido libre",
        value: "FREE",
    }
]

export const iconElements = [
    {
        value: "EMAIL",
        Icon: MailIcon
    },
    {
        value: "AIDA_EMAIL",
        Icon: MailIcon
    },
    {
        value: "SOCIAL_POST",
        Icon : PostSocialIcon
    },
    {
        value: "AD",
        Icon: AdSocialIcon
    },
    {
        value: "PITCH",
        Icon: PitchVentaIcon
    },
    {
        value: "VIDEO_SCRIPT",
        Icon: GuionIcon
    },
    {
        value: "LANDING_PAGE",
        Icon: SellPageIcon
    },
    {
        value: "FREE",
        Icon : PostSocialIcon
    },
]

// export const baseUrl = "http://localhost:3000/";
export const baseUrl = "https://api.copyassistant.ai/";
