import iconNav from "@/assets/icons/ri_arrow-drop-left-line.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Spinner } from "@/components/Spinner";
import { useCreateProject } from "@/hooks/useCreateProject";
import { ICreateProject } from "@/models";



const CreateProject = ({ changeCreateState }: ICreateProject) => {
    const { changeInput, create, values, isLoad, error, } = useCreateProject()

    const checkEmptyValues = () => values.name.length === 0
    const handleClickBack = () => !isLoad ? changeCreateState() : null

    return (

        <>
            {
                error.length > 0 ? <MessageComponent title={error} type="error" /> : <></>
            }
            <div className="ca__createProject">
                <p className="ca__backtext" onClick={handleClickBack} > <img src={iconNav} alt="back" /> Regresar a mis proyectos</p>
                <div className="ca__divider"></div>
                <h4>Crear nuevo proyecto</h4>
                <div className="ca__createProject-inputs">
                    <label htmlFor="">Nombre del proyecto</label>
                    <input type="text" name="name" id="name" placeholder="Ej. Facebook ADS" value={values.name} onChange={changeInput} />
                    {/* <label htmlFor="description">Descripción (Opcional)</label>
                    <textarea id="description" name="description" placeholder="Descripción del proyecto" value={values.description} onChange={changeInput}></textarea> */}
                    <button
                        className={`ca__principalButton ${checkEmptyValues() ? "ca__disableButton" : ""}`}
                        onClick={!checkEmptyValues() && !isLoad ? ()=>create(changeCreateState) : () => { }}
                    >
                        {
                            isLoad ? <Spinner cssClass="ca__spinner-create" /> : "Crear Proyecto"
                        }
                        
                    </button>
                </div>
            </div>
        </>
    )
}

export default CreateProject;