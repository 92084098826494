import iconIan from "@/assets/icons/diamond.svg";
import iconIanWhite from "@/assets/icons/diamong-white.svg";
import CardTemplate from "@/components/Template/CardTemplate";
import { AppStore } from "@/models";
import { useState } from "react";
import { useSelector } from "react-redux";




const Templates = () => {

    const [filter, setFilter] = useState<"all" | "ian" | "regular">("all")

    const { templates } = useSelector((store: AppStore) => store.templates);
    const { account } = useSelector((store: AppStore) => store.user!.user!);

    const templatesInOrder = () => {
        var templatesAux = templates.slice();

        return templatesAux.sort(function (a, b) {
            if (a.exclusive === b.exclusive) {
                return 0;
            }
            // Si a.exclusive es verdadero, lo colocamos antes que b.exclusive
            // De lo contrario, lo colocamos después
            return a.exclusive ? -1 : 1;
        });
    }

    const filterIanTemplates = () => templates.filter((t) => t.exclusive);
    const filterRegularTemplates = () => templates.filter((t) => !t.exclusive);

    const onClickFilter = (value: "all" | "ian" | "regular") => setFilter(value);


    return (<>
        {
            // !currentTemplate ?
            <div className="ca__templates ca__second-container">
                <h4>Plantillas</h4>
                {
                    !account.exclusive &&
                    <div className="ca_templates-filter">
                        <div className={`${filter === "all" && "ca_templates-filter-active"} `} onClick={() => onClickFilter("all")} >Todas</div>
                        <div
                            className={`${filter === "ian" && "ca_templates-filter-active"} `} onClick={() => onClickFilter("ian")}
                        >
                            <img className="ian-icon" src={iconIan} alt="ian" />
                            <img className="ian-icon-white" src={iconIanWhite} alt="iconIanWhite" />
                            Exclusivo de IAN </div>
                        <div
                            className={`${filter === "regular" && "ca_templates-filter-active"} `} onClick={() => onClickFilter("regular")}
                        >Regular</div>
                    </div>
                }
                <div className="ca__templates-flex">
                    {
                        account.exclusive &&
                        filterRegularTemplates().map((template, i) => <CardTemplate key={template.id} template={template} index={i + 1} />)

                    }
                    {
                        (!account.exclusive && filter === "all") &&
                        <>
                            {
                                templatesInOrder().map((template, i) => <CardTemplate key={template.id} template={template} index={i + 1} />)
                            }
                        </>
                    }
                    {
                        (!account.exclusive && filter === "ian") &&
                        <>
                            {
                                filterIanTemplates().map((template, i) => <CardTemplate key={template.id} template={template} index={i + 1} />)
                            }
                        </>
                    }
                    {
                        (!account.exclusive && filter === "regular") &&
                        <>
                            {
                                filterRegularTemplates().map((template, i) => <CardTemplate key={template.id} template={template} index={i + 1} />)
                            }
                        </>
                    }
                </div>
            </div>
            // : <ContentCreateForm />
        }
    </>);
}

export default Templates;