import { Spinner } from '@/components/Spinner';
import { useSingleProject } from '../../hooks/useSingleProject';
import { ContentCard, CreateContent, FiltersComponent, HeaderProyects, NoContents } from "../Proyects";

const SingleProject = () => {

    const { project, isCreate, changeCreateState, inputSearch, contentsFiltered, inputText, loading } = useSingleProject();

    return (
        <div className="ca__proyects ca__second-container">
            <div className="ca__proyects-container">

                {
                    loading ?
                        <div className="ca__proyects-loading">
                            <div>
                                <Spinner />
                                <h4>Obteniendo datos del proyecto...</h4>
                            </div>
                        </div>
                        :
                        !project ? <></> :
                            isCreate ?
                                <CreateContent changeCreateState={changeCreateState} />
                                :
                                <>
                                    <HeaderProyects changeCreateState={changeCreateState} />
                                    {
                                        project?.elements.length === 0 ? <NoContents changeCreateState={changeCreateState} />
                                            :
                                            <>
                                                <FiltersComponent inputSearch={inputSearch} />
                                                <div className="ca__contents">
                                                    {
                                                        inputText.current.length > 0 && contentsFiltered.length === 0 ?
                                                            <h2 className="ca__text-nofiltered">No se encontró ningún contenido</h2>
                                                            :
                                                            (contentsFiltered.length > 0 ? contentsFiltered : project!.elements).map((element, i) => <ContentCard key={i} content={element} i={i + 1} />)
                                                    }
                                                </div>
                                            </>
                                    }
                                </>
                }
            </div>
        </div>
    )
}

export default SingleProject;