import { PlanCardInterface, AppStore } from '@/models';
import React from 'react';
import { useSelector } from 'react-redux';


const PlanCard: React.FC<PlanCardInterface> = ({ planName, totalTokens, }) => {
	const { totalWords, expiredAt } = useSelector((store: AppStore) => store.user.user!.account);

	const createStringDate = () => {
		const date = new Date(expiredAt);
		const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
		const month = date.getMonth() + 1 < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	}

	return (
		<div className="ca__planCard">
			<div className="ca__planCard-info">
				<div className="ca__planCard-info-title">
					<h4>{planName}</h4>
				</div>
				<p className='ca__planCard-info-tokens'><span>{totalTokens.toLocaleString('es-MX')}</span> <br /> palabras por mes</p>
				{/* {
					typePlan !== "proAnnual" ?
					<p className='ca__planCard-info-tokens'><span>{totalTokens.toLocaleString('es-MX')}</span> tokens</p>
					:<></>
				} */}
				{/* <p className='ca__planCard-info-cost'><span>{monthCost}$/</span>{timeCost}</p> */}
				{/* {
					typePlan === "proAnnual"?
					<p className="ca__planCard-infoAnnual">Paga anual y recibe 100,00 tokens al mes + 2 meses gratis</p> :<></>
				} */}
			</div>
			<div className="ca__planCard-tokens">
				<p className="ca__planCard-tokens-text"><span>{totalWords!.toLocaleString('es-MX')}</span> palabras restantes</p>
				<div className="ca__planCard-tokens-line">
					<div
						style={{
							width: `${totalWords! * 100 / totalTokens}%`
						}}

						className="ca__planCard-tokens-line-use"></div>
				</div>
				<p className='ca__planCard-tokens-date'>Se renueva en {createStringDate()}</p>

			</div>

		</div>
	);
};

export default PlanCard;
