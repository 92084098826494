
export const blockBackground = (openModal: boolean) =>{
    const root = document.getElementById("root");
		if (openModal) {
			if (root) {
				root.style.height = "100vh";
				root.style.overflow = "hidden";
			}
		} else {
			if (root) {
				if (root.style.height === "100vh") {
					root.style.height = "auto";
					root.style.overflow = "auto";
				}
			}
		}
}