import { ICopyCard } from "@/models";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-html-to-clipboard";
import moment from 'moment';
import 'moment/locale/es';
import { Modal } from "@/components/Modal";


const CopyCard = ({ copy, copyInClipboard, handleOnChange, isEditable = true, date, isNewCopy, favorite, onClick,deleteFunction,loadDelete }: ICopyCard) => {
    const textarea = React.createRef<HTMLTextAreaElement>();
    const divRef = React.createRef<HTMLDivElement>();
    const [viewMore, setviewMore] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const changeModal = () => setOpenModal((e) => !e);
    // moment.locale("es");

    const changeViewMore = () => setviewMore((e) => !e);

    useEffect(() => {
        if (divRef.current) {
            divRef!.current!.innerHTML = copy;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copy]);


    return (
        <>
            <div className="ca__editor-card">
                <div className="ca__editor-card-date">
                    <div className="ca__editor-date">
                        <p><i className="ri-calendar-line"></i>{moment(date).format('D MMM YYYY')}</p>
                        <p><i className="ri-time-line"></i>{moment(date).format('HH:mm')}</p>
                    </div>
                    {
                        !isNewCopy ?
                            <div className="ca__cardCopy-favorite" onClick={onClick}>
                                {
                                    !favorite ?
                                        <i className="ri-heart-line" ></i> :
                                        <i className="ri-heart-fill" ></i>
                                }
                            </div>
                            : <></>
                    }
                </div>
                <textarea ref={textarea} style={{ display: 'none' }} defaultValue={copy} ></textarea>
                <div className="k-widget k-editor" >
                    <div className="k-editor-content" style={{ width: '100%', fontFamily: 'Karla' }}>
                        <div role={'textbox'} className="ca__editComponent_editor" ref={divRef} contentEditable={isEditable} onBlur={() => handleOnChange(divRef)} style={{
                            height: copy.length > 418 && !isNewCopy ? viewMore ? "100%" : "185px" : "100%",
                            overflow: "hidden",
                            // WebkitLineClamp: 10,
                            // lineClamp: 10,
                            // textOverflow: "ellipsis",
                            // display: "-webkit-box",
                            // WebkitBoxOrient: "vertical"

                        }} />
                        {
                            copy.length > 418 && !isNewCopy ?
                                <p className="ca__editor-more" onClick={changeViewMore} >
                                    {
                                        viewMore ? "Ver menos" : "... Ver todo"
                                    }
                                </p> : <></>
                        }
                    </div>
                    <div className="ca__copyCard-buttonsFlex">
                        <CopyToClipboard text={copy}
                            options={{ asHtml: true }}
                            onCopy={copyInClipboard}>
                            <div className="ca__secondButton">
                                <i className="ri-file-copy-2-line"></i>
                                <p>Copiar</p>
                            </div>
                        </CopyToClipboard>
                        {
                            !isNewCopy ?
                                <button className="ca__secondButton ca__button-delete" onClick={changeModal} >
                                    <i className="ri-delete-bin-line"></i>
                                    Eliminar
                                </button> : <></>
                        }

                    </div>


                </div>
            </div>
            {
                openModal ? <Modal cssClass="ca__modal-bg" changeModal={changeModal} buttonAcceptText="Eliminar" description="Este copy no volverá a estar disponible en tu historial" title="¿Seguro de eliminar este copy?" deleteProject={deleteFunction} isLoad={loadDelete} /> : <></>
            }
        </>
    )
}

export default CopyCard