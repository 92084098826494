import { baseUrl } from "@/constants";

import axios from 'axios';


const templateUrl = baseUrl + 'templates';

export const getTemplateFetch = async(token: string) =>{
    try {
       const resp = await axios.get(templateUrl,{
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return resp.data;
    } catch (error) {
        console.log("Error in getTemplateFetch");
        console.log(error);
        return null;
    }
}
