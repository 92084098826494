import { AppStore, IStateCreateProject } from "@/models";
import { createProject } from "@/redux/states";
import { createProjectFetch } from "@/services";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";



export const useCreateProject = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);
    const [values, setValues] = useState<IStateCreateProject>({
        description: "",
        name: "",
    })
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState("");

    const changeInput = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => setValues({ ...values, [e.target.name]: e.target.value })

    const create = async (changeCreateState : ()=>void) => {
        setIsLoad(true);
        const sendValues = {
            name: values.name,
            description: values.description,
        }
        let resp = await createProjectFetch(sendValues, token!);
        if (resp) {
            resp.elements = [];
            dispatch(createProject(resp));
            changeCreateState();
        } else {
            setError("Error al crear el proyecto");
            setTimeout(() => {
                setError("");
            }, 5000)
        }
        setIsLoad(false);

    }


    return {
        create,
        changeInput,
        values,
        isLoad,
        error,
    }
}