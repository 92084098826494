import ContentCreateForm from "@/components/ContentCreateForm/ContentCreateForm";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { useEditComponent } from "@/hooks/useEditComponent";

const Edit = () => {
	const { changeValues, isLoad, values, regenarate, message, type, values2, changeValues2, content, checkCompleteData } = useEditComponent();

	const checkExistArray = () => {
		let index = content?.template?.fields.findIndex((e) => e.maxOccurs > 1) ?? -1;
		if (index > 1) {
			if (values2[content?.template?.fields[index].name ?? ""]) {
				return true;
			}
			return false;
		}
		return true
	}

	return (
		<div className={`ca__edit ${!content ? "ca__full-height" : ""}`}>
			{
				!content ?
					<ContentCreateForm /> :
					<>
						{
							message.length > 0 ? <MessageComponent title={message} type={type} /> : <></>
						}

						<div className="ca__edit-inputs">
							<div className="ca__edit-inputs-title">
								{
									content.template?.resources.icon ?
										<i className={content.template?.resources.icon}></i>
										: <i className="ri-file-list-3-line" ></i>
								}
								{/* <h4>{content?.name}</h4> */}
								<h4>{content.template?.name}</h4>
							</div>
							{
								content && content.type !== "FREE" ?
									<>
										{
											content.template?.id && Object.keys(values2).length > 0 && (checkExistArray()) ?
												content.template.fields.map((e) => {
													const value = e.maxOccurs > 1 ? null : values2[e.name];
													return <div key={e.label}>
														<div className="ca__formlabel-container">
															<label htmlFor={e.name}>{e.label} <sup>*</sup></label>
															{
																e.maxOccurs === 1 && Object.keys(values2).length > 0 && e.type !== "select" && e.type !== "textarea" && e.maxLength && e.maxLength > 0 ?
																	<p className="ca__text-counter">{value.length}/{e.maxLength} caracteres</p>
																	: <></>
															}
														</div>
														{
															[...Array(e.maxOccurs)].map((_, index) => {
																return (
																	<div key={index}>
																		{
																			e.maxOccurs > 1 && e.type !== "textarea" && e.maxLength && e.maxLength > 0 ?
																				<div className={`ca__multipleinput-counter ${index === 0 ? "ca__multiplemargin" : ""}`}>
																					<p className="ca__text-counter">{values2[e.name][index]['value'].length}/{e.maxLength} caracteres</p>
																				</div>
																				: <></>
																		}
																		{
																			e.type === "textarea" ?
																				<textarea key={e.name} name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} id={e.name} placeholder={e.placeholder} onChange={changeValues2} value={e.maxOccurs > 1 ? values2[e.name][index]['value'] : values2[e.name]} ></textarea>
																				: e.type === "select" ?
																					<select key={e.name} name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} onChange={changeValues2} value={e.maxOccurs > 1 ? values2[e.name][index]['value'] : values2[e.name]}>
																						{
																							Object.keys(e.options!).map((a) =>
																								<option value={a}>{e.options![a]}</option>
																							)
																						}
																					</select>
																					:
																					<input key={e.name} type={e.type} name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} id={e.name} placeholder={e.placeholder} onChange={changeValues2} value={e.maxOccurs > 1 ? values2[e.name][index]['value'] : values2[e.name]} maxLength={e.maxLength && e.maxLength > 0 ? e.maxLength : undefined} />
																		}
																	</div>)
															}

															)
														}

													</div>

												}) :
												<>
													<div className="ca__formlabel-container">
														<label>Producto o servicio <sup>*</sup></label>
														{/* <p className="ca__text-counter">{values.product.length}/80 caracteres</p> */}
													</div>
													<input type="text" placeholder="Ej. Shampoo anti pulgas RUMANX" name="product" value={values.product} onChange={changeValues}/>
													<label>Contexto o situación <sup>*</sup></label>
													{/* <Counter blocText="Explícanos con detalle la situación o contexto de lo que quieres lograr" /> */}
													<textarea name="brief" placeholder='Ej. Estamos presentando un nuevo champú con ingredientes naturales y queremos que las personas lo conozcan y lo prueben.' value={values.brief} onChange={changeValues} ></textarea>
													<label>Objetivo <sup>*</sup></label>
													<textarea name="objective" placeholder='Ej. Vender el shampoo RUMANX' value={values.objective} onChange={changeValues}></textarea>
													{
														content.type === "LANDING_PAGE" ?
															<div className="ca__lastinputs">
																<label>3 Características del producto <sup>*</sup></label>
																{/* <div className="ca__multipleinput-counter ca__multipleinput-margin">
																	<p className="ca__text-counter">{values.characteristic1?.length}/80 caracteres</p>
																</div> */}
																<input type="text" name='characteristic1' placeholder='Característica 1' value={values.characteristic1} onChange={changeValues} />
																{/* <div className="ca__multipleinput-counter">
																	<p className="ca__text-counter">{values.characteristic2?.length}/80 caracteres</p>
																</div> */}
																<input type="text" name='characteristic2' placeholder='Característica 2' value={values.characteristic2} onChange={changeValues} />
																{/* <div className="ca__multipleinput-counter">
																	<p className="ca__text-counter">{values.characteristic3?.length}/40 caracteres</p>
																</div> */}
																<input type="text" name='characteristic3' placeholder='Característica 3' value={values.characteristic3} onChange={changeValues} />

															</div>
															: <></>
													}
												</>
										}
									</>
									: <></>
							}

						</div>

						<div className="ca__edit-button">
							<button className={` ca__principalButton ${checkCompleteData() ? "" : "ca__disableButton"}`} onClick={(!isLoad && checkCompleteData()) ? regenarate : () => { }} >
								{
									isLoad ? "Generando..." : "Crear copy"
								}
							</button>

						</div>
					</>
			}

		</div>

	)
}

export default Edit