import { AppStore } from "@/models";
import { patchElement, updateInfoUser } from "@/redux/states";
import { getInfoUser, regenerateCopyFetch, updateContentsFetch } from "@/services";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEditComponent = () => {
    const dispatch = useDispatch();
    const content = useSelector((store: AppStore)=> store.projects.currentContent);
    const project = useSelector((store: AppStore) => store.projects.currentProject);
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);

    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState<any>("error");
    const [values, setValues] = useState<any>({
        // ...content?.fields
        product: content?.product ?? "",
        brief: content?.brief ?? "",
        objective: content?.objective ?? "",
        characteristic1: content?.features ? content.features[0] ?? "" : "",
        characteristic2: content?.features ? content.features[1] ?? "" : "",
        characteristic3: content?.features ? content.features[2] ?? "" : "",
    })

    const [values2, setValues2] = useState<any>({
        // ...content?.fields
    })

    


    const changeValues2 = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        let nameSplit = e.target.name.split('-');

        if (nameSplit.length >= 2) {
            let index = Number(nameSplit[1]);
            let values = [...values2[nameSplit[0]]]

            let existsIndex = values.find(v => v.index === index);
            if (existsIndex !== undefined) {
                values[index].value = e.target.value;
            } else {
                values = [...values, { index, value: e.target.value }]
            }

            setValues2({
                ...values2,
                [nameSplit[0]]: values
            });
        } else {
            setValues2({
                ...values2,
                [e.target.name]: e.target.value
            });
        }
    }

    const changeValues = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const updateNewData = async () => {
        let element: any = {};
        const value = {
            ...values2
        };
        if (content?.template?.id) {
            Object.keys(value).forEach((e) => {
                if (Array.isArray(value[e])) {
                    value[e] = value[e].map((v: any) => v.value);
                }
            })

            element = {
                fields: {
                    ...value,
                }
            }

        } else {
            const features = [];
            if (values.characteristic1 !== "") features.push(values.characteristic1);
            if (values.characteristic2 !== "") features.push(values.characteristic2);
            if (values.characteristic3 !== "") features.push(values.characteristic3);
            const newData: {
                product: string;
                brief: string;
                objective: string;
                features?: string[];
            } = {
                product: values.product,
                brief: values.brief,
                objective: values.objective,
                features
            }
            if (content?.type !== "LANDING_PAGE") {
                delete newData.features;
            }
            element = newData
        }
        
        const resp = await updateContentsFetch(project!.id, content!.id, token!, element);
        if (resp) {
            // dispatch(patchElement(resp));
        } else {
            setMessage("Error al actualizar datos del contenido");
            setType("error")
            setTimeout(() => {
                setMessage("");
            }, 3000)
        }
        
    }


    const regenarate = async () => {
        setIsLoad(true);
        await updateNewData();
        const resp = await regenerateCopyFetch(project!.id, content!.id, token!);

        if (resp) {
            const infoUser = await getInfoUser(token!);
            if (infoUser) {
                dispatch(updateInfoUser(infoUser));
                // ! ACTUALIZAR EL CONTENIDO ACTUAL
                dispatch(patchElement(resp));
            }
            setMessage("Nuevo copy generado con éxito");
            setType("done")
            setTimeout(() => {
                setMessage("");
            }, 3500)
        }else{
            setMessage("Error al generar nuevo contenido");
            setType("error")
            setTimeout(() => {
                setMessage("");
            }, 3000)
        }
        setIsLoad(false);
    }

    const checkCompleteData = () => {
        let isComplete = true;
        if(content?.template?.id){
            content.template.fields.forEach((t:any) => {
                if (!values2[t.name]) isComplete = false;
            })
        }else{
            if(values.brief.length === 0 || values.objective.length === 0 || values.product.length === 0) isComplete = false
            if(content?.type === "LANDING_PAGE")  if(values.characteristic1.length === 0 || values.characteristic2.length === 0 || values.characteristic3.length === 0) isComplete =false
        }
        return isComplete;
    }

    useEffect(() => {
        if (!content?.template?.id) {
            setValues({
                product: content?.product ?? "",
                brief: content?.brief ?? "",
                objective: content?.objective ?? "",
                characteristic1: content?.features ? content.features[0] ?? "" : "",
                characteristic2: content?.features ? content.features[1] ?? "" : "",
                characteristic3: content?.features ? content.features[2] ?? "" : "",
            })
        } else {
            if (content && content.template.id) {
                let data: any = {};
                const fields = content.fields;
                Object.keys(fields).forEach((e) => {
                    if (Array.isArray(fields[e])) {
                        const array: any[] = fields[e];
                        let newArray: any[] = [];
                        array.forEach((v, i) => newArray.push({ index: i, value: v }))
                        data = {
                            ...data,
                            [e]: newArray
                        }
                    } else {
                        data = {
                            ...data,
                            [e]: fields[e]
                        }
                    }
                });
                
                setValues2(data);
            }
        }
    }, [content]);



    return {
        values,
        isLoad,
        changeValues,
        changeValues2,
        updateNewData,
        regenarate,
        message,
        type,
        values2,
        content,
        checkCompleteData
    }
}

