import { baseUrl } from "@/constants";
import { ModelLogin } from "@/models";

import axios from 'axios';
import { getInfoUser } from "./user.service";


const loginUrl = baseUrl + 'auth/login';

export const loginUser = async ({ email, password }: ModelLogin) => {
    try {
        const data = {
            username: email,
            password
        }
        const response = await axios.post(loginUrl, data)
        // ? Get info user
        const infoUser = await getInfoUser(response.data.accessToken);

        // console.log("infoUser");
        // console.log(infoUser);
        // console.log(response.data);
        return {
            accessToken: response.data.accessToken,
            expiresIn: response.data.expiresIn,
            ...infoUser
        }
    } catch (error:any) {
        console.log("Error in loginUser");
        console.log(error);
        console.log(error.response?.data?.code);
        return error.response?.data?.code ?? error.code;
    }
}

export const recoveryPassword = async (email:string) =>{
    try {
        
    } catch (error) {
        console.log("Error in recoveryPassword");
        console.log(error);
        return null;
    }
}
