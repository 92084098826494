import logo2 from "@/assets/icons/icon-login.svg";
import iconPass from "@/assets/icons/ri_lock-password-line.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Spinner } from "@/components/Spinner";
import { useFormReset } from '@/hooks/useFormReset';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from "@/assets/icons/icon-total.svg";

export interface ForgotPasswordInterface { }

const ForgotPassword: React.FC<ForgotPasswordInterface> = () => {
	const navigate = useNavigate();
	const { values, handleChange, onClickSend, handleBlur, isLoad, message, type } = useFormReset({
		password: "",
		repassword: "",
		error: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showRepassword, setshowRepassword] = useState(false);
	const { password, repassword, error } = values;

	const handleShowPassword = () => setShowPassword((e) => !e);
	const handleShowRePassword = () => setshowRepassword((e) => !e);

	return (
		<div className="ca__loginScreen">
			{
				message.length > 0 ? <MessageComponent title={message} type={type} />
					: <></>
			}
			<div className="ca__loginScreen-container">
				<div>
					<img className="ca__login-iconletters" src={logo2} alt="Copy assistant" />
					<img className="ca__login-icon" src={logo} alt="Copy assistant" />
					<p>Genera copys que convierten en cuestión de segundos con inteligencia artificial.</p>
					{/* <img className="ca__login-pc" src={iconPc} alt="pc" /> */}
				</div>
				<div>
					<div className="ca__loginScreen-container-info">
						<div className="ca__login-container-forgot">
							<label htmlFor="password">Contraseña</label>
							<div>
								<img src={iconPass} alt="Password" />
								<input
									className="ca__forgot-password"
									type={showPassword ? "text" : "password"}
									name="password"
									id="password"
									placeholder="Tu contraseña"
									value={password}
									onChange={handleChange}
								/>
								 {
                                        !showPassword ?
                                            <i onClick={handleShowPassword} className="ri-eye-line"></i> :
                                            <i onClick={handleShowPassword} className="ri-eye-off-line"></i>
                                    }
							</div>
							<label htmlFor="password">Repite la contraseña</label>
							<div>
								<img src={iconPass} alt="Password" />
								<input
									type={showRepassword ? "text" : "password"}
									name="repassword"
									id="repassword"
									placeholder="Repite la contraseña"
									value={repassword}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								 {
                                        !showRepassword ?
                                            <i onClick={handleShowRePassword} className="ri-eye-line"></i> :
                                            <i onClick={handleShowRePassword} className="ri-eye-off-line"></i>
                                    }
							</div>
							{
								error ?
									<div className="ca__forgot-error">
										{error}
									</div> : <></>
							}
						</div>
						<div
							className="ca__loginScreen-btnLogin ca__buttonforgot"
							onClickCapture={() => {
								if (!isLoad) {
									onClickSend(window.location.pathname.slice(17));
								}
							}}
						>
							{isLoad ? <Spinner cssClass="" /> : "Establecer contraseña"}
						</div>
						<p
							className="ca__recoverPass-volver"
							onClickCapture={() => navigate("/login")}
						>
							Regresar al login
						</p>
					</div>
				</div>
			</div>
		</div>
	)
};

export default ForgotPassword;
