import closeIcon from "@/assets/icons/ri_close-line.svg";
import moreIcon from "@/assets/icons/select-icon.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Spinner } from "@/components/Spinner";
import { AppStore } from "@/models";
import { updateUser } from "@/redux/states";
import { cancelSuscriptionService, updateUserService } from "@/services";
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { UserPlan } from "./UserPlan";

const Account = () => {
    const [showContainer, setShowContainer] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [showPassword, setshowPassword] = useState(false)
    const [showRePassword, setshowRePassword] = useState(false)
    const [message, setMessage] = useState("");
    const [type, setType] = useState<"error" | "warning" | "info" | "done">("error");
    const [showForm, setShowForm] = useState(false);
    const [isLoadReason, setIsLoadReason] = useState(false);
    const [reason, setReason] = useState("");

    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const user = useSelector((store: AppStore) => store.user.user);
    const [values, setValues] = useState<{
        name?: string,
        email?: string,
        password?: string,
        repassword?: string,
    }>({
        name: user?.name,
        email: user?.email,
        password: "",
        repassword: "",
    })

    const handlePassword = () => setshowPassword((e) => !e);
    const handleRePassword = () => setshowRePassword((e) => !e);


    const showMessage = (message: string, type: "error" | "warning" | "info" | "done") => {
        setMessage(message);
        setType(type)
        setTimeout(() => {
            setMessage("");
            setType("error")
        }, 4000);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleClickUpdate = async () => {
        if (values.password && values.password.length < 8) {
            showMessage("La contraseña debe ser de mínimo 8 caracteres", "warning");
            return;
        }
        if (values.password !== values.repassword) {
            showMessage("La contraseñas no coinciden", "warning");
            return false;
        }
        setIsLoad(true);

        if (values.password === '' && values.repassword === '') {
            delete values.password;
            delete values.repassword;
        }


        const resp = await updateUserService(user?.id!, user?.accessToken!, values);
        if (resp) {
            const dataToUpdate = {
                ...user,
                name: resp.name
            };
            dispatch(updateUser(dataToUpdate));

            setValues({
                name: resp?.name,
                email: resp?.email,
                password: "",
                repassword: "",
            })
            showMessage("Tus datos se han actualizado correctamente", "done")
        } else {
            showMessage("Error al actualizar tus datos", "error")
        }
        setIsLoad(false);
    }

    const handleClickReason = async () => {
        setIsLoadReason(true);

        //TODO: Hacer consulta
        if (reason.length === 0) {
            showMessage("Necesitas agregar una razón", "warning");
        } else {
            const resp = await cancelSuscriptionService(user!.email, "CopyAssistant", reason);
            if (resp) {
                showMessage("Tu solicitud se envío con éxito", "done");
                setShowForm(false);
                setReason("");
            } else {
                showMessage("No se pudo enviar tu solicitud", "error");
            }
        }
        setIsLoadReason(false);
    }
    // const backToProjects = () => navigate("/", { replace: false })

    return (
        <>
            {
                message.length > 0 ? <MessageComponent title={message} type={type} /> : <></>
            }
            <div className="ca__account ca__second-container">
                <div className="ca__account-container">
                    {/* <p className="ca__account-back" onClick={() => backToProjects()}>
                        <img src={iconNav} alt="back" />
                        Regresar a mis proyectos
                    </p> */}
                    <h4>Mi Cuenta</h4>
                    <div className="ca__account-container-flex">
                        <div className="ca__account-inputs">
                            <label>Nombre</label>
                            <input type="text" name="name" placeholder="Nombre" value={values.name} onChange={handleChange} />
                            <label>Correo</label>
                            <input type="text" name="email" placeholder="Correo Electrónico" value={values.email} onChange={handleChange} readOnly disabled />
                            <p className="ca__account-textPassword" onClick={() => setShowContainer((e) => !e)}>Cambiar contraseña</p>
                            {
                                showContainer ?
                                    <div className="ca__container-password">
                                        <img onClick={() => setShowContainer(false)} src={closeIcon} alt="close" />
                                        <label htmlFor="password">Contraseña</label>
                                        <div className="ca__account-inputpass">
                                            <input type={showPassword ? "text" : "password"} name="password" id="password" placeholder='Contraseña' value={values.password} onChange={handleChange} />
                                            {
                                                !showPassword ?
                                                    <i onClick={handlePassword} className="ri-eye-line"></i>
                                                    : <i onClick={handlePassword} className="ri-eye-off-line"></i>
                                            }
                                        </div>
                                        <label htmlFor="repassword">Confirmar nueva contraseña</label>
                                        <div className="ca__account-inputpass">
                                            <input type={showRePassword ? "text" : "password"} name="repassword" id="repassword" placeholder='Confirmar contraseña' value={values.repassword} onChange={handleChange} />
                                            {
                                                !showRePassword ?
                                                    <i onClick={handleRePassword} className="ri-eye-line"></i>
                                                    : <i onClick={handleRePassword} className="ri-eye-off-line"></i>
                                            }
                                        </div>
                                    </div> : <></>
                            }
                            <button onClick={handleClickUpdate} >{
                                isLoad ? <Spinner cssClass="ca__spinner-create" /> : "Guardar cambios"
                            }</button>
                        </div>
                        {/* <div className="ca__account-profile">
                        <div className="ca__account-dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <div className='ca__account-dropzone-content'>
                                        <img src={profilePhoto} alt="Profile" />
                                        <p>Arrastra aquí tu imagen de perfil o</p>
                                        <p className='ca__account-dropzone-text'>Subir foto</p>
                                    </div>
                            }
                        </div>
                    </div> */}

                    </div>
                    <UserPlan />

                    <div className="ca__account-footer">
                        <div className="ca__account-footer-cancel">
                            <p onClick={() => setShowForm(e => !e)} >Anular suscripción <img className={`${showForm && "icon-close-cancel"}`} src={moreIcon} /> </p>
                        </div>
                        <div className={`ca__account-inputs ${showForm && "ca__open-form"} `}>
                            <label htmlFor="email">Correo</label>
                            <input type="text" defaultValue={user?.email} disabled={true} />
                            <label htmlFor="reason">Razón</label>
                            <textarea value={reason} onChange={(e) => setReason(e.target.value)} name="reason" placeholder="Escribe la razón por la que quieres cancelar tu suscripción..."></textarea>
                            <button onClick={handleClickReason}>
                                {isLoadReason ? <Spinner cssClass="ca__spinner-create" /> : "Cancelar suscripción"}
                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default Account;