import actionIcon from "@/assets/icons/ri_more-2-fill.svg";
import { Modal } from "@/components/Modal";
import { typesObjectives } from "@/constants";
import { blockBackground } from "@/helpers";
import { IContentCard } from "@/models";
import { AppStore } from '@/models/store';
import { deleteElement, selectCurrentElement } from "@/redux/states";
import { deleteElementFetch } from "@/services";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";


const ContentCard = ({ content, i }: IContentCard) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const project = useSelector((store: AppStore) => store.projects.currentProject);
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);

    const [openSubMenu, setopenSubMenu] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [isLoad, setIsLoad] = useState(false);

    const selectContent = () => {
        dispatch(selectCurrentElement(content))
        navigate(`/project/${project?.id}/content/${content.id}`);
    }
    const changeModal = () => setOpenModalDelete((e) => !e);

    const onClickDeleteElement = async () => {
        setIsLoad(true);
        const resp = await deleteElementFetch(project!.id, content.id, token!);
        if (resp) {
            dispatch(deleteElement(content.id));
            changeModal();
        }
        setIsLoad(false);
    }

    useEffect(() => {
        blockBackground(openModalDelete);
    }, [openModalDelete])

    return (
        <>
            {
                openModalDelete ?
                    <Modal cssClass="ca__modal-bg" changeModal={changeModal} buttonAcceptText="Eliminar" title="¿Deseas eliminar este contenido?" isLoad={isLoad} description="Esta acción es irreversible" deleteProject={() => onClickDeleteElement()} /> : <></>
            }
            {
                <div className={`ca__contentCard ${i % 3 === 0 && i !== 0 ? "ca__contentCard-impar" : ""} `}>
                    <div className="ca__contentCard-title-icon" onClick={() => setopenSubMenu((e) => !e)}>
                        <img src={actionIcon} alt="more" />
                        {
                            openSubMenu ?
                                <div className="ca__header-submenu">
                                    <div onClick={() => setOpenModalDelete(true)} > <i className="ri-delete-bin-line" style={{ color: "rgb(234, 58, 58)" }} ></i> Eliminar</div>
                                </div> : <></>

                        }
                    </div>
                    <div onClick={selectContent} >
                        <div className="ca__contentCard-title">
                            <h4>{content.name}</h4>

                        </div>
                        <div className="ca__content-info">
                            <div className="ca__contentCard-type">
                                {
                                    content.template?.name && content.template.name.length > 0 ?
                                        content.template.name :
                                        typesObjectives.filter((e) => e.value === content.type)[0].name
                                }
                            </div>
                            <div className="ca__contentCard-dateEdit">
                                <p>Editado</p>
                                <p>{content.updatedAt ? moment(content.updatedAt).format("DD/MM/YYYY HH:mm:ss") : "Sin modificar"}</p>
                            </div>

                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default ContentCard;