import { IModal } from "@/models"
import { WarningComponent } from "./WarningComponent"


const Modal = ({cssClass,changeModal,buttonAcceptText="",description="",title="",deleteProject,isLoad=false,isGenerate=false}: IModal) =>{
    return(
        <div className={`ca__modal ${cssClass ?? ""}`}>
                <WarningComponent isLoad={isLoad} changeModal={changeModal} buttonAcceptText={buttonAcceptText} description={description} title={title} deleteProject={deleteProject!} isGenerate={isGenerate} />
        </div>
    )
}

export default Modal