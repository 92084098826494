
import logo2 from "@/assets/icons/icon-login.svg";
import logo from "@/assets/icons/icon-total.svg";
import iconPc from "@/assets/icons/img-pc.svg";
import iconPass from "@/assets/icons/ri_lock-password-line.svg";
import iconMail from "@/assets/icons/ri_mail-line.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import Spinner from "@/components/Spinner/Spinner";
import { useLogin } from "@/hooks/useLogin";
import { AppStore } from "@/models";
import { KeyboardEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
    const navigate = useNavigate()
    const { values, handleChange, onClickSend, fetchLogin, error } = useLogin({
        email: "",
        password: "",
    });
    const { email, password } = values;
    const [showPassword, setShowPassword] = useState(false);
    const accessToken = useSelector((store: AppStore) => store.user.user?.accessToken);

    const onKeyEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") onClickSend()
    }

    const handleShowPassword = () => setShowPassword((e) => !e);

    useEffect(() => {
        if (accessToken) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])


    return (
        <>
            <div className="ca__loginScreen">
                <div className="ca__loginScreen-container">
                    <div>
                        <img className="ca__login-iconletters" src={logo2} alt="Copy assistant" />
                        <img className="ca__login-icon" src={logo} alt="Copy assistant" />
                        <p>Genera copys que convierten en cuestión de segundos con inteligencia artificial.</p>
                        <img className="ca__login-pc" src={iconPc} alt="pc" />
                    </div>
                    <div>
                        <div className="ca__loginScreen-container-info">
                            <h4>
                                Iniciar Sesión
                            </h4>
                            <div>
                                <label htmlFor="email">
                                    Email
                                </label>
                                <div>
                                    <img src={iconMail} alt="Password" />
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Correo"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <label htmlFor="password">
                                    Contraseña
                                </label>
                                <div>
                                    <img src={iconPass} alt="Password" />
                                    <input
                                        className="ca__login-password"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        id="password"
                                        placeholder="Contraseña"
                                        value={password}
                                        onChange={handleChange}
                                        onKeyUp={onKeyEnter}
                                    />
                                    {
                                        !showPassword ?
                                            <i onClick={handleShowPassword} className="ri-eye-line"></i> :
                                            <i onClick={handleShowPassword} className="ri-eye-off-line"></i>
                                    }
                                </div>
                            </div>
                            {/* <p onClickCapture={() => history.push("/recover-password")}> */}
                            <p onClickCapture={() => navigate("/recover-password")}>
                                ¿Olvidaste tu contraseña?
                            </p>
                            <div
                                className="ca__loginScreen-btnLogin"
                                onClickCapture={() => {
                                    if (!fetchLogin) {
                                        onClickSend();
                                    }
                                }}
                            >
                                {fetchLogin ? (
                                    <Spinner cssClass="ca__spinner-login" />
                                ) : (
                                    "Iniciar sesión"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error.length > 0 ?
                    <MessageComponent title={error} type="error" /> : <></>
            }
        </>
    );
};

export default LoginScreen;
