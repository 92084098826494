import iconNote from "@/assets/icons/notes.svg";
import { INoProject } from "@/models";



const NoProjects = ({ changeCreateState }:INoProject) => {
    return (
        <div className="ca__noProjects">
            <div>
                <img src={iconNote} alt="notes" />
                <h4>Aún no tienes proyectos</h4>
                <p>Crea tu primer proyecto para que comiencen a llover esos copys.</p>
                <button onClick={changeCreateState} >Crear proyecto</button>
            </div>
        </div>
    )
}

export default NoProjects;