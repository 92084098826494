import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@/helpers";
import Sidebar from '@/components/Sidebar/Sidebar';

const ProtectedRoutes = () =>{
    const isAuth = useAuth(); 
    return isAuth ? <div className="ca__principal">
    <Sidebar />
    <Outlet />
    </div> : <Navigate to="/login" />
}

export default ProtectedRoutes;