import deleteIcon from "@/assets/icons/ri_delete-bin-4-line.svg";
import actionIcon from "@/assets/icons/ri_more-2-fill.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Modal } from "@/components/Modal";
import { useGridCard } from "@/hooks/useGridCard";
import { IGridCard } from "@/models";
import moment from "moment";

const GridCardProject = ({ project }: IGridCard) => {
    const {changeOpenActions,handleDeleteClick,openActions,openModal,selectCurrentProject,changeModal,deleteProject,isLoad,error} = useGridCard(project);
    const { elements, name, updatedAt } = project;

    return (
        <>
        {
            error.length > 0 ? <MessageComponent title={error} type="error" /> :<></>
        }
            <div className="ca__gridCardProject">
                <div className="ca__gridCardProject-info" onClick={selectCurrentProject}>
                    <h4>{name}</h4>
                    <div className="ca__gridCardProject-info-category">{elements?.length ?? 0}</div>
                    {/* <p className="ca__gridCardProject-numPages">{numPages}</p> */}
                    <p className="ca__gridCardProject-lastTime">{updatedAt ? moment(updatedAt).format("DD/MM/YYYY HH:mm:ss")  : "Sin modificar"}</p>
                </div>
                <div className="ca__gridCardProject-actions" onClick={changeOpenActions}>
                    <img src={actionIcon} alt="Actions" />
                    {
                        openActions ?
                            <div className="ca__gridCardProject-actions-modal">
                                <div className="ca__gridCardProject-delete" onClick={handleDeleteClick}>
                                    <img src={deleteIcon} alt="delete" />
                                    Eliminar
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
            {
                openModal ? <Modal cssClass="ca__modal-bg" changeModal={changeModal} buttonAcceptText="Eliminar" description="Esta acción es irreversible" title="¿Seguro de eliminar este proyecto?" deleteProject={deleteProject} isLoad={isLoad} /> : <></>
            }
        </>
    );
}

export default GridCardProject;