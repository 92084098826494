import searchIcon from "@/assets/icons/ri_search-line.svg";
import { IFiltersComponent } from "@/models";
import { useSelector } from 'react-redux';
import { AppStore } from '../../../models/store';

const FiltersComponent = ({
    inputSearch
}:IFiltersComponent) => {
    const project = useSelector((store: AppStore) => store.projects.currentProject);
    
    return(
        <div className="ca__filtersComponent">
            <div className="ca__filtersComponent-containerInput">
                <img src={searchIcon} alt="search" />
                <input type="text" placeholder={project ? "Buscar contenido": "Buscar proyecto"} onChange={inputSearch} />
            </div>
        </div>
    )
}

export default FiltersComponent;