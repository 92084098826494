import { blockBackground } from "@/helpers";
import { AppStore, ProjectTypes } from "@/models";
import { deleteProjectId, selectProject } from "@/redux/states";
import { getDeleteProjectFetch } from "@/services";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export const useGridCard = (project: ProjectTypes) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openActions, setOpenActions] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState("");
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);

    // const { elements, name, lastModificate } = project;

    const selectCurrentProject = () => {
        dispatch(selectProject(project));
        navigate(`/project/${project.id}`)
    }

    const changeOpenActions = () => setOpenActions((e) => !e);

    const changeModal = () => setOpenModal((e) => !e);

    const handleDeleteClick = () => {
        changeModal();
    }

    const deleteProject = async () => {
        setIsLoad(true);
        
        const resp = await getDeleteProjectFetch(project.id, token!);
        if (resp) {
            blockBackground(false);
            dispatch(deleteProjectId(project.id));
        } else {
            setError("No se pudo eliminar el proyecto")
            setTimeout(() => {
                setError("");
            }, 5000);
        }
        setIsLoad(false);
        changeModal();
    }

    useEffect(()=>{
        blockBackground(openModal);
    },[openModal]);

    return {
        handleDeleteClick,
        changeOpenActions,
        selectCurrentProject,
        openActions,
        openModal,
        changeModal,
        deleteProject,
        isLoad,
        error
    }
}