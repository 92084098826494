import { AppStore, ProjectTypes } from "@/models";
import { ChangeEvent, useRef, useState } from "react";
import { useSelector } from "react-redux";

export const useFiltersComponent = () => {
    // const [gridActive, setGridActive] = useState(false);
    const [projectsFiltered, setProjectsFiltered] = useState<ProjectTypes[]>([]);
    const projects = useSelector((e:AppStore)=> e.projects.projects);
    const inputText = useRef<string>("");


    // const setDistribution = (value: boolean) => {
    //     setGridActive(value);
    // }

    const inputSearch = (e:ChangeEvent<HTMLInputElement>) =>{
        const text = e.target.value;
        inputText.current = text ?? ""
        let tempArray = projects.filter((e)=> e.name.toLowerCase().includes(text.toLowerCase()));
        setProjectsFiltered(tempArray)
    }

    return {inputSearch,projectsFiltered,inputText };
}