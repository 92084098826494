import { baseUrl } from "@/constants";

import axios from 'axios';


const recoverUrl = baseUrl + 'recover-password';

export const recoverPassword = async(email:string) =>{
    try {
        await axios.post(recoverUrl,{email});
        // console.log(resp.data);
        return true;
    } catch (error) {
        console.log("Error in recoverPassword");
        console.log(error);
        return false;
    }
}
export const recoverPasswordHash = async(password: string, hash: string) =>{
    try {
        await axios.post(`${recoverUrl}/${hash}`,{password});
        // console.log(resp.data);
        return true;
    } catch (error) {
        console.log("Error in recoverPassword");
        console.log(error);
        return false;
    }
}