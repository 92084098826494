import deleteIcon from "@/assets/icons/ri_delete-bin-4-line.svg";
import MessageComponent from "@/components/MessageComponent/MessageComponent";
import { Modal } from "@/components/Modal";
import { useGridCard } from "@/hooks/useGridCard";
import { ICardListProject, ProjectTypes } from "@/models";
import { selectProject } from "@/redux/states";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CardListProject = ({ project }: ICardListProject) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {changeModal,changeOpenActions,deleteProject,error,handleDeleteClick,isLoad,openActions,openModal} = useGridCard(project);

    const handleClickProject = (project: ProjectTypes) => {
        dispatch(selectProject(project));
        navigate("/");
    }

    return (
        <div>
            {
                error.length > 0 ? <MessageComponent title={error} type="error" /> : <></>
            }
            <div key={`project`} className="ca__dashboard-cardproject" >
                <div className='ca__dashboard-cardproject-name' onClick={() => handleClickProject(project)}>
                    {project?.name}
                </div>
                <div className="ca__dashboard-cardproject-date" onClick={() => handleClickProject(project)}>
                    {
                        project?.updatedAt ? moment(project.updatedAt).format("DD/MM/YYYY HH:mm:ss") : "Sin modificar"
                    }
                </div>
                <div className="ca__dashboard-cardproject-icon" onClick={changeOpenActions}>
                    <i className='ri-more-2-fill'></i>
                    {
                        openActions ?
                            <div className="ca__gridCardProject-actions-modal">
                                <div className="ca__gridCardProject-delete" onClick={handleDeleteClick}>
                                    <img src={deleteIcon} alt="delete" />
                                    Eliminar
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
            {
                openModal ? <Modal cssClass="ca__modal-bg" changeModal={changeModal} buttonAcceptText="Eliminar" description="Esta acción es irreversible" title="¿Seguro de eliminar este proyecto?" deleteProject={deleteProject} isLoad={isLoad} /> : <></>
            }
        </div>
    );
}

export default CardListProject;