import closeIcon from "@/assets/icons/ri_close-line.svg";
import warningIcon from "@/assets/icons/warning-icon.svg";
import { Spinner } from '@/components/Spinner';
import { WarningComponentInterface } from "@/models";
import { setLocalStorage } from '@/utilities';
import React, { ChangeEvent, useState } from 'react';


const WarningComponent: React.FC<WarningComponentInterface> = ({ title, description, buttonAcceptText, changeModal, deleteProject, isLoad, isGenerate }) => {

	const [check, setCheck] = useState(false)
	const onChangeRadio = (e:ChangeEvent<HTMLInputElement>) =>{
			setCheck(e.target.checked);
	}
	const handleClick = () =>{
		if(isGenerate){
			if(check){
				setLocalStorage("ca__showGenerate", "true");
			}
		}
		deleteProject()
	}
	return (
		<div className="ca__warningComponent">
			<img src={warningIcon} alt="warning" />
			<img src={closeIcon} alt="close" className='ca__warningComponent-close' onClick={changeModal} />
			<h4>{title}</h4>
			<p>{description}</p>
			{
				isGenerate ?
					<div className="ca__warningComponent-show">
						<input type="checkbox" onChange={onChangeRadio} checked={check} />
						<p>No volver a mostrar</p>
					</div> : <></>
			}
			<div className="ca__warningComponent-buttons">
				<button onClick={changeModal} className="ca__warningComponent-cancel">Cancelar</button>
				<button onClick={handleClick} className="ca__warningComponent-accept">{
					isLoad ? <Spinner cssClass='ca__spinner-create' /> :
						buttonAcceptText
				}</button>
			</div>

		</div>
	);
};

export default WarningComponent;
