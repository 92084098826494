import { IMessageComponent } from "@/models";
import MessageIcon from "@/components/icons/MessageIcon";
import iconDone from "@/assets/icons/done-icon.svg";


const MessageComponent = ({title, type}:IMessageComponent) =>{
    return (
        <div className={`ca__message ca__message-${type}`}>
            <MessageIcon />
            <img className="ca__message-icondone" src={iconDone} alt="" />
            <p>{title}</p>
        </div>
    )
}

export default MessageComponent;