import { Spinner } from "@/components/Spinner";


const Loader = () => {
    return (
        <div className="ca__container-load">
            <Spinner cssClass="ca__spinner-projects" />
            <h4 className="ca__textLoad">Obteniendo tus proyectos...</h4>
        </div>)
}


export default Loader;