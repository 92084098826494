import { baseUrl } from "@/constants";
import { ProjectTypes } from "@/models";

import axios from 'axios';


const projectUrl = baseUrl + 'projects';


const getContentProject = async (id: string, token: string) => {
    try {
        const resp = await axios.get(`${projectUrl}/${id}/contents`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return resp.data;
    } catch (error) {
        console.log("Error in getContentProject");
        console.log(error);
        return null
    }
}

export const getProjectsFetch = async (token: string) => {
    try {
        const response = await axios.get(projectUrl, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        let projects: ProjectTypes[] = [];

        for await (const project of response.data) {
            const data = await getContentProject(project.id, token);
            if (data) {
                projects.push({
                    ...project,
                    elements: data
                })
            }
        }

        return projects;
    } catch (error) {
        console.log("Error in getProjectsFetch");
        console.log(error);
        return [];
    }
}

export const createProjectFetch = async (values: any, token: string) => {
    try {
        const response = await axios.post(projectUrl, values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log("Error in createProjectFetch");
        console.log(error);
        return null
    }
}
export const editProjectFetch = async (values: any, token: string, idProject: string) => {
    // console.log("Edit");
    // console.log(values);
    try {
        const response = await axios.patch(`${projectUrl}/${idProject}`, values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        // console.log("Edit response");
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log("Error in editProjectFetch");
        console.log(error);
        return null
    }
}

export const createElementFetch = async (values: any, projectId: string, token: string) => {
    try {
        // console.log(values);

        const response = await axios.post(`${projectUrl}/${projectId}/contents`, values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log("Error in createElementFetch");
        console.log(error);
        return null;
    }
}


export const getInfoProjectFetch = async (id: string, token: string) => {
    try {
        const response = await axios.get(`${projectUrl}/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        const data = await getContentProject(id, token);
        return {
            ...response.data,
            elements: data ?? []
        }
    } catch (error) {
        console.log("Error in getInfoProjectFetch");
        console.log(error);
        return null;
    }
}

export const getDeleteProjectFetch = async (id: string, token: string) => {
    try {
        const resp = await axios.delete(`${projectUrl}/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        // console.log(resp.data);
        return resp.data.length === 0;
    } catch (error) {
        console.log("Error in getDeleteProjectFetch");
        console.log(error);
        return false;
    }

}

export const deleteElementFetch = async (idProject: string, idElement: string, token: string) => {
    try {
        const resp = await axios.delete(`${projectUrl}/${idProject}/contents/${idElement}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return resp.data.length === 0;

    } catch (error) {
        console.log("Error al eliminar contenido");
        console.log(error);
        return false
    }
}

export const updateCopyFetch = async (idProject: string, idElement: string, token: string, copy: string) => {
    try {
        const resp = await axios.patch(`${projectUrl}/${idProject}/contents/${idElement}`, {
            copy
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        // TODO: Retornar algo
        return resp.data;

    } catch (error) {
        console.log("Error en update Copy");
        console.log(error);
        return null;
    }
}

export const updateContentsFetch = async (idProject: string, idElement: string, token: string, values: any) => {
    try {
        const resp = await axios.patch(`${projectUrl}/${idProject}/contents/${idElement}`, {
            ...values
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return resp.data;
        // TODO: Retornar nuevos valores
    } catch (error) {
        console.log("Error in Update Contents fetch");
        console.log(error);
        return null;
    }
}

export const regenerateCopyFetch = async (idProject: string, idElement: string, token: string) => {
    try {
        const resp = await axios.patch(`${projectUrl}/${idProject}/contents/${idElement}/regenerate`, {

        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return resp.data;
    } catch (error) {
        console.log("Error in regenerateCopyFetch");
        console.log(error);
        return null;
    }
}


export const genreateFreeContent = async (idProject: string, idElement: string, token: string, query: string) => {
    try {
        const resp = await axios.post(`${projectUrl}/${idProject}/contents/${idElement}/generate`, {
            query
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            },

        })
        // console.log("Free content");
        // console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("Error in generate");
        console.log(error);
        return null;
    }
}

export const getHistoryFetch = async (proejctId: string, contentId: string, token: string) => {
    try {
        const resp = await axios.get(`${projectUrl}/${proejctId}/contents/${contentId}/choices`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return resp.data;

    } catch (error) {
        console.log("Error in getHistoryFetch");
        console.log(error);

        return null;
    }
}

export const changeFavoriteChoice = async (proejctId: string, contentId: string, token: string, idChoice: string, favorite: boolean) => {
    try {
        const resp = await axios.patch(`${projectUrl}/${proejctId}/contents/${contentId}/choices/${idChoice}`, {
            favorite
        },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("Error in changeFavoriteChoice");
        console.log(error);
        return null;
    }
}

export const deleteChoice = async (proejctId: string, contentId: string, token: string, idChoice: string) => {
    try {
        await axios.delete(`${projectUrl}/${proejctId}/contents/${contentId}/choices/${idChoice}`,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return true;
    } catch (error) {
        console.log("Error in changeFavoriteChoice");
        console.log(error);
        return false;
    }
}

export const getContentInfo = async (idProject: string, idElement: string, token: string) => {
    try {
        const resp = await axios.get(`${projectUrl}/${idProject}/contents/${idElement}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return resp.data;
    } catch (error) {
        console.log("Error in getContentInfo");
        console.log(error);
        return null;
    }
}