

const TableHeader = () => {
    return (
        <div className="ca__projects-headers">
            <p className="ca__projects-header-name">Nombre del proyecto</p>
            <p className="ca__projects-header-category">Contenidos</p>
            <p>Úlltima modificación </p>
        </div>
    )
}

export default TableHeader;