import iconSketch from "@/assets/icons/sketch.svg";
import logo from "@/assets/img/CopyAssistant-logo.svg";
import store from '@/redux/store';
import { AppRouter } from '@/routes/AppRouter';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

function App() {
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    if (window.screen.width < 550) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener('resize', () => {
      if (window.screen.width < 550) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    })
  }, [])


  return (
    !isMobile ?
      <Provider store={store} >
        <AppRouter />
      </Provider>
      :
      <>
        <div className="ca__mobileEnable-header">
          <img src={logo} alt="Copy Assistant" />
        </div>
        <div className="ca__mobileEnable">

          <img src={iconSketch} alt="icon" className="ca__mobileEnable-icon" />
          <h4>Versión móvil en construcción</h4>
          <p>Estamos trabajando en la versión móvil de Copy Assistant. Mientras puedes <b>accede desde tu computadora.</b></p>
          <a href="https://copyassistant.ai/" >Regresar al inicio</a>
        </div>
      </>
  );
}

export default App;
