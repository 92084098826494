import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@/helpers";

// const useAuth = () => {
//     const stateUser = useSelector((store: AppStore) => store.user);
//     console.log("stateUser");
//     console.log(stateUser);
    
//     return stateUser && stateUser.loggedIn;
// }


const PublicRoute = () =>{
    const isAuth = useAuth(); 
    return !isAuth ? <Outlet /> : <Navigate to="/" />
}

export default PublicRoute;