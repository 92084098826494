import { Header } from "@/components/Header";
import { Spinner } from "@/components/Spinner";
import { AppStore } from "@/models";
import { getContentInfo, getInfoProjectFetch } from "@/services";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Edit, Editor } from "./components";
import { selectCurrentElement, selectProject } from '@/redux/states';

const SingleContent = () => {
    const navigate = useNavigate();
    const dispacth = useDispatch();

    const [loading, setloading] = useState(false);

    const { currentContent, currentProject } = useSelector((store: AppStore) => store.projects);
    const { currentTemplate } = useSelector((store: AppStore) => store.templates);
    const { user } = useSelector((store: AppStore) => store.user);

    const returnReloadInfo = async () => {
        setloading(true);
        const path = window.location.pathname.slice(9);
        const pathSplit = path.split("/");
        console.log(path);
        console.log(pathSplit[0])
        console.log(pathSplit[2])
        const idProject = pathSplit[0];
        const idContent = pathSplit[2];
        const project = await getInfoProjectFetch(idProject, user!.accessToken);
        if (project) {
            dispacth(selectProject(project));
            const content = await getContentInfo(idProject, idContent, user!.accessToken);
            if (content) {
                dispacth(selectCurrentElement(content));
            } else {
                navigate("/", { replace: true });
            }
        } else {
            navigate("/", { replace: true });
        }

        setloading(false);

    }

    useEffect(() => {
        if (!currentProject && !currentContent && user?.accessToken) returnReloadInfo();
    }, [user?.accessToken])
    return (
        <>
            {

                <div className="ca__second-container ca__single-margin" >
                    {
                        loading ?
                            <div className="ca__singleContent-load">
                                <div>
                                    <Spinner />
                                </div>

                            </div> :
                            !currentContent && !currentProject && !currentTemplate ? <></>
                                :
                                <>
                                    <div style={{
                                        maxHeight: `${!currentContent ? "0" : "93px"}`,
                                        overflow: "hidden",
                                        transition: "max-height .7s ease",
                                        position: "relative",
                                        zIndex: "2",
                                        boxShadow: "0px 1px 2px rgb(0 0 0 / 4%), 0px 12px 16px rgb(0 0 0 / 3%)"
                                    }} >
                                        <Header />
                                    </div>
                                    <div className="ca__singleProject">
                                        <Edit />
                                        <Editor />
                                    </div>
                                </>
                    }
                </div>
            }

        </>
    );
}

export default SingleContent;