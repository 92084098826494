import iconNav from "@/assets/icons/ri_arrow-drop-left-line.svg";
import MessageComponent from '@/components/MessageComponent/MessageComponent';
import { useCreateElements } from '@/hooks/useCreateElement';
import { AppStore } from '@/models/store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const ContentCreateForm = () => {
    // const location = useLocation();
    const navigate = useNavigate();
    const { changeValuesStep2, error, typeMessage, template, createContent, setName, name, checkCompleteData, project, projects, changeSelectProject, projectSelected, valueStep2, valuesProject, changeInput } = useCreateElements();

    const loading = useSelector((store: AppStore) => store.projects.loading);

    const backFunction = () => project ? navigate("/") : navigate("/templates");

    return (
        <>
            {
                error.length > 0 ? <MessageComponent title={error} type={typeMessage} /> : <></>
            }
            <div className="ca__content-create-form">
                <div className="ca__contentCreateForm">
                    <div className="ca__contentCreateForm-first">
                        <p className="ca__backtext" onClick={backFunction} ><img src={iconNav} alt="back" />{
                            !project ? "Regresar a plantillas" : `Regresar al proyecto: ${project.name}`
                        }</p>
                        <div className="ca__contentCreateForm-title">
                            {
                                template?.resources.icon ?
                                    <i className={template?.resources.icon}></i>
                                    : <i className="ri-file-list-3-line" ></i>
                            }
                            <h4>{template?.name}</h4>
                        </div>
                        <div className="ca__contentCreateForm-divider"></div>
                        {
                            projects.length > 0 && !project ?
                                <label>Selecciona un proyecto</label>
                                : <></>
                        }
                        {
                            projects.length === 0 ?
                                <div className="ca__contentCreate-createproject">
                                    <h4>Crea tu primer proyecto</h4>
                                    <label htmlFor="">Nombre del proyecto</label>
                                    <input type="text" name="name" id="name" placeholder="Ej. Facebook ADS" value={valuesProject.name} onChange={changeInput} />
                                    {/* <label htmlFor="description">Descripción (Opcional)</label>
                                    <textarea id="description" name="description" placeholder="Descripción del proyecto" value={valuesProject.description} onChange={changeInput}></textarea> */}

                                </div>
                                :
                                !project ?
                                    <select name="project" id="project" onChange={(e) => changeSelectProject(e.target.value)} value={projectSelected?.id}>
                                        {
                                            projects.map((project) => <option key={project.id} value={project.id}>{project.name}</option>)
                                        }
                                    </select>
                                    : <></>
                        }
                        <div className="ca__formlabel-container">
                            <label htmlFor="name">Nombre del contenido <sup>*</sup></label>
                        </div>
                        <input className="ca__formCreate-name" type="text" placeholder='Ej: Correo de venta para base de datos' id='name' name="name" onChange={(e) => setName(e.target.value)} value={name} />
                        <p className="ca__contentCreate-helptext">Solo para referencia, no afecta el resultado</p>
                        <div className="ca__contentCreate-divider"></div>
                        {Object.keys(valueStep2).length === 0 ? <></> : template?.fields.map((e, i) => {
                            const value = e.maxOccurs > 1 ? null : valueStep2[e.name];
                            return <div key={i}>
                                <div className="ca__formlabel-container">
                                    <label htmlFor={e.name}>{e.label} <sup>*</sup></label>
                                    {
                                        e.maxOccurs === 1 && Object.keys(valueStep2).length > 0 && e.type !== "select" && e.type !== "textarea" && e.maxLength && e.maxLength > 0 ?
                                            <p className="ca__text-counter">{value.length}/{e.maxLength} caracteres</p>
                                            : <></>
                                    }
                                </div>
                                {
                                    [...Array(e.maxOccurs)].map((_, index) => {
                                        return (
                                            <div key={`index-${index}`}>
                                                {
                                                    e.maxOccurs > 1 && e.type !== "textarea" && e.maxLength && e.maxLength > 0 ?
                                                        <div className={`ca__multipleinput-counter ${index === 0 ? "ca__multiplemargin" : ""}`}>
                                                            <p className="ca__text-counter">{valueStep2[e.name][index]['value']?.length}/{e.maxLength} caracteres</p>
                                                        </div>
                                                        : <></>
                                                }
                                                {
                                                    e.type === "textarea" ?
                                                        <textarea name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} id={e.name} placeholder={e.placeholder} onChange={changeValuesStep2} value={e.maxOccurs > 1 ? valueStep2[e.name][index]['value'] : valueStep2[e.name]}></textarea>
                                                        : e.type === "select" ?
                                                            <select name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} onChange={changeValuesStep2} value={e.maxOccurs > 1 ? valueStep2[e.name][index]['value'] : valueStep2[e.name]}>
                                                                {
                                                                    Object.keys(e.options!).map((a) =>
                                                                        <option key={a} value={a}>{e.options![a]}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            :
                                                            <input type={e.type} name={e.maxOccurs > 1 ? `${e.name}-${index}` : e.name} id={e.name} placeholder={e.placeholder} onChange={changeValuesStep2} value={e.maxOccurs > 1 ? valueStep2[e.name][index]['value'] : valueStep2[e.name]} maxLength={e.maxLength && e.maxLength > 0 ? e.maxLength : undefined} />
                                                }
                                            </div>)
                                    }

                                    )
                                }

                            </div>

                        })}
                    </div>
                    <div className="ca__contentCreateForm-second">
                        <button
                            className={`ca__principalButton ${checkCompleteData() ? "" : "ca__disableButton"}`}
                            onClick={checkCompleteData() && !loading ? () => createContent() : () => { }}
                        >
                            {
                                loading ? "Generando ..." : "Crear copy"
                            }

                        </button>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ContentCreateForm;