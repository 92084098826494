import { baseUrl } from "@/constants";
import axios from 'axios';


const userUrl = baseUrl + 'users';

export const getInfoUser = async(token: string) =>{
    try {
        const resp = await axios.get(`${userUrl}/me`,{
            headers:{
                "Authorization": `Bearer ${token}`
            }
        });
        return resp.data;
    } catch (error) {
        console.log("Error en getInfoUser");
        console.log(error);
        return null
    }
}


export const updateUserService = async (id: string, token: string,data: any) => {
    try {
        const resp = await axios.patch(`${userUrl}/${id}`, data ,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return resp.data;
    } catch (error) {
        console.log("Error in Update user");
        console.log(error);
        return null;
    }
}

export const cancelSuscriptionService = async (email:string, platform:string, reason:string) => {
    try {
        await axios.post("https://api.vilma.ai/users/cancel/suscription", {
            email,
            platform,
            reason
        })
        return true;
        
    } catch (error) {
        console.log(error);
        return false
        
    }
}