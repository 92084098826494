import { AppStore } from '@/models';
import { getProjects } from "@/redux/states";
import { getProjectsFetch } from "@/services";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useProjects = () => {
    const dispatch = useDispatch();
    const [createproject, setcreateproject] = useState(false)
    const [isLoad, setIsLoad] = useState(false);
    const accessToken = useSelector((store: AppStore)=> store.user.user?.accessToken)
    

    const changeCreateState = () => setcreateproject((e)=> !e);

    const startGetProjects = async()=>{
        setIsLoad(true);
        const resp = await getProjectsFetch(accessToken!);
        // ! Dispatch
        dispatch(getProjects(resp));
        setIsLoad(false);
    }

    

    useEffect(()=>{
        startGetProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    

    return { createproject, isLoad,changeCreateState };
}