import iconEmpty from "@/assets/icons/empty_state.svg";
import { INoContents } from "@/models";


const NoContents = ({changeCreateState}:INoContents) => {

    return (
        <div className="ca__emptyState">
            <img src={iconEmpty} alt="Empty" />
            <h4>Aún no tienes contenidos</h4>
            <p>Crea tu primer contenido para generar copys</p>
            <button className="ca__principalButton" onClick={changeCreateState} >Crear contenido</button>
        </div>
    )
}

export default NoContents;