import { IProtectedConstant } from "@/models";
import { Account, Dashboard, Proyects, SingleProject, Templates,SingleContent } from "@/screens";

export const protectedRoutes:IProtectedConstant[] = [
    {
        path: "/project/:hash/content",
        name: "Contenido seleccionado",
        component: SingleContent,
        showInMenu: false
    },
    {
        path: "/project/:hash/content/:hash",
        name: "Proyecto seleccionado",
        component: SingleContent,
        showInMenu: false
    },
    {
        path: "/project/:hash",
        name: "Proyect",
        component: SingleProject,
        showInMenu: false
    },
    {
        path: "/dashboard-test",
        name: "Dashboard",
        component: Dashboard,
        showInMenu: false
    },
    {
        path: "/",
        name: "Proyectos",
        component: Proyects,
        showInMenu: true
    },
    {
        path: "/templates",
        name: "Plantillas",
        component: Templates,
        showInMenu: true
    },
    {
        path: "/account",
        name: "Mi cuenta",
        component: Account,
        showInMenu: true
    }
  ]