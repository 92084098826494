import iconNav from "@/assets/icons/ri_arrow-drop-left-line.svg";
import { ICreateContent } from "@/models";
import { Templates } from "@/screens/Templates";
import { useSelector } from 'react-redux';
import { AppStore } from '../../../models/store';


const CreateContent = ({ changeCreateState }: ICreateContent) => {
    const project = useSelector((store:AppStore)=> store.projects.currentProject);
    return (
        <div className="ca__createProject">
            <p className="ca__backtext" onClick={changeCreateState} > <img src={iconNav} alt="back" /> Regresar al proyecto: {project?.name}</p>
            <div className="ca__divider"></div>
            <Templates />
        </div>
    )
}

export default CreateContent;