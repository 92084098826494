import { AppStore, ITemplate } from '@/models';
import { selectCurrentTemplate } from '@/redux/states';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CardListProject from './components/CardListProject';
import DashboardSubtitle from './components/DashboardSubtitle';


const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const templates = useSelector((store: AppStore) => store.templates.templates)
    const projects = useSelector((store: AppStore) => store.projects.projects)


    const handleClickTemplate = (template: ITemplate) => {
        dispatch(selectCurrentTemplate(template));
        navigate("/project");
    }


    return (
        <div className="ca__second-container ca__dashboard">
            <h3>Dashboard</h3>
            <hr />
            <div id="cbox-88Dl6GxjQ2J1UtFH"></div>
            <DashboardSubtitle route='/templates' title='Plantillas' textMore="Ver todas" />
            <div className="ca__dashboard-templates">
                {
                    [1, 2, 3, 4].map((i) => <div key={`card-${i}`} className='ca__dashboard-cardTemplate' onClick={() => handleClickTemplate(templates[i])} >
                        <div className="ca__dashboard-card-icon">
                            <i className={templates[i]?.resources.icon ?? "ri-file-list-3-line"} ></i>
                        </div>
                        <p>{templates[i]?.name}</p>
                    </div>)
                }
            </div>
            {
                projects.length > 0 ?
                    <>
                        <DashboardSubtitle route='/' title='Últimos proyectos' textMore="Ver todos" />
                        <div className="ca__dashboard-projects">
                            {
                                (projects.length > 4 ? [1, 2, 3, 4]:projects).map((i:any) => <CardListProject project={projects.length > 4 ?projects[i] : i} key={`project-${projects.length >4 ?i : i.id}`} />)
                            }
                        </div>
                    </>
                    : <></>
            }
        </div>
    );
}

export default Dashboard;