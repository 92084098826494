
export const filterCodeError = (code: string) =>{
    let message = ""
    switch (code) {
        case "ERR_NETWORK":
            message = "Error de conexión"
            break;
        case "UNAUTHORIZED":
            message = "Correo y/o contraseña incorrectos"
            break;
        case "NOT_FOUND":
            message = "No existe una cuenta asociada a este email"
            break;
        default:
            message = "Hubo un error. Vuelve a intentarlo"
            break;
    }
    return message;
}