import { AppStore, IElements } from "@/models";
import { selectProject } from "@/redux/states";
import { getInfoProjectFetch } from "@/services";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useSingleProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const inputText = useRef<string>("");

    const [isCreate, setIsCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contentsFiltered, setcontentsFiltered] = useState<IElements[]>([])

    const project = useSelector((store: AppStore) => store.projects.currentProject);
    const { user } = useSelector((store: AppStore) => store.user);




    const changeCreateState = () => setIsCreate((e) => !e);
    const inputSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        inputText.current = text ?? ""
        let tempArray = project?.elements.filter((e) => e.name.toLowerCase().includes(text.toLowerCase()));
        setcontentsFiltered(tempArray ?? [])
    }
    const getInfoProject = async () => {
        setLoading(true);
        const id = window.location.pathname.slice(9)
        const resp = await getInfoProjectFetch(id, user!.accessToken);
        if (resp) {
            dispatch(selectProject(resp));
        } else {
            navigate('/', {replace: true})
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!project && user?.accessToken) getInfoProject();
    }, [user?.accessToken])

    return {
        // Variables
        project,
        isCreate,
        inputSearch,
        inputText,
        contentsFiltered,
        loading,
        // Functions
        changeCreateState,
    }
}