import { AppStore, ProjectTypes } from "@/models";
import { changeLoading, createElement, createProject, updateInfoUser } from "@/redux/states";
import { createElementFetch, createProjectFetch, getInfoUser } from "@/services";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCreateElements = () => {
    const dispatch = useDispatch();

    const { currentProject: project, projects } = useSelector((store: AppStore) => store.projects);
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);
    const template = useSelector((state: AppStore) => state.templates.currentTemplate);

    const [name, setName] = useState("")
    const [valueStep2, setValueStep2] = useState<any>({
    });
    const [error, setError] = useState("");
    const [typeMessage, setTypeMessage] = useState<"error" | "warning" | "info">("error");
    const [projectSelected, setProjectSelected] = useState<ProjectTypes>(projects[0]);
    const [valuesProject, setValuesProject] = useState({
        name: "",
        description: ""
    });

    const changeInput = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => setValuesProject({ ...valuesProject, [e.target.name]: e.target.value })

    const changeValuesStep2 = (e: any) => {

        let nameSplit = e.target.name.split('-');
        if (nameSplit.length >= 2) {
            let index = Number(nameSplit[1]);
            // index =  1
            let values = [...valueStep2[nameSplit[0]]]

            let existsIndex = values.find(v => v.index === index);
            if (existsIndex !== undefined) {
                values[index].value = e.target.value;
            } else {
                values = [...values, { index, value: e.target.value }]
            }

            setValueStep2({
                ...valueStep2,
                [nameSplit[0]]: values
            });
        } else {
            setValueStep2({
                ...valueStep2,
                [e.target.name]: e.target.value
            });
        }
    }

    const changeSelectProject = (id: string) => {
        setProjectSelected(projects.filter((e) => e.id === id)[0]);
        // dispatch(selectProject(projects.filter((e)=> e.id === id)[0]))
    }

    const startFetch = async (element: any, projectCreate?:any) => {
        const newProject = projectCreate ?? project ?? projectSelected;
        const resp = await createElementFetch(element, newProject.id, token!);
        if (resp) {
            const infoUser = await getInfoUser(token!);
            if (infoUser) {
                dispatch(updateInfoUser(infoUser));
            }

            dispatch(createElement(
                {
                    element: resp,
                    project: newProject
                }
            ));
            window.history.pushState(null, "", `content/${resp.id}`)
        } else {
            setError("Error al crear el contenido");
            setTypeMessage("error")
            setTimeout(() => {
                setError("");
            }, 5000)
        }
    }

    const create = async () => {
        const sendValues = {
            name: valuesProject.name,
            description: valuesProject.description,
        }
        let resp = await createProjectFetch(sendValues, token!);
        if (resp) {
            resp.elements = [];
            dispatch(createProject(resp));
            return resp;
        } else {
            setError("Error al crear el proyecto");
            setTimeout(() => {
                setError("");
            }, 5000);
            return null;
        }
    

    }
    const createContent = async () => {
        try {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            // ! Check if don't exist projects
            let resp:any = null;
            if(projects.length === 0){
                resp = await create()
            }
            let element: any = {};
            // ? Crear Copy en Proyecto
            dispatch(changeLoading(true));
            Object.keys(valueStep2).forEach((e) => {
                if (Array.isArray(valueStep2[e])) {
                    valueStep2[e] = valueStep2[e].map((v: any) => v.value);
                }
            })
            
            element = {
                template: template?.id,
                name,
                fields: {
                    ...valueStep2,
                }
            }
            
            await startFetch(element, resp);
            dispatch(changeLoading(false));
        } catch (error) {
            console.log("Error in create Content coding");
            console.log(error);
            dispatch(changeLoading(false));
            setError("Error al crear el contenido");
            setTypeMessage("error")
            setTimeout(() => {
                setError("");
            }, 5000)
            
        }
    }

    const checkCompleteData = () => {
        let isComplete = true;
        if (name.length === 0) return false
        template?.fields.forEach((t) => {
            if (!valueStep2[t.name]) isComplete = false;
        })
        if(projects.length === 0 ) if(valuesProject.name.length === 0) isComplete = false;
        return isComplete;
    }

    useEffect(() => {
        
        if (template && Object.keys(valueStep2).length === 0) {
            let data: any = {};
            template.fields.forEach((e) => {
                if(e.maxOccurs > 1){
                    let newArray: any[] = [];
                    for (let index = 0; index < e.maxOccurs; index++) {
                        newArray.push({
                            index,
                            value: ""
                        })
                        
                    }
                    data = { 
                        ...data,
                        [e.name]: newArray
                    }
                }else{
                    data = {
                        ...data,
                        [e.name]: e.options ? Object.keys(e.options)[0] : ''
                    }
                }
            });
            
            setValueStep2(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template])


    return {
        createContent,
        changeValuesStep2,
        error,
        typeMessage,
        checkCompleteData,
        template,
        setName,
        name,
        project,
        projects,
        changeSelectProject,
        projectSelected,
        valueStep2,
        valuesProject,
        changeInput
    }
}

