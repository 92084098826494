import { IDashboardSubtitle } from "@/models";
import { useNavigate } from "react-router-dom";


const DashboardSubtitle = ({route, title, textMore}:IDashboardSubtitle) => {
    const navigate = useNavigate();
    
    const nanigateTo = () => navigate(route);
    return (
        <div className="ca__container-subtitle">
            <h4>{title}</h4>
            <p className="ca__dashboard-textMore" onClick={nanigateTo}>{textMore} <i className="ri-arrow-drop-right-line"></i></p>
        </div>
    );
}

export default DashboardSubtitle;