import { useSelector } from 'react-redux';
import { AppStore } from '@/models';
import { PlanCard } from './PlanCard';

// let myPlan: "basic" | "pro" | "proAnnual" = "proAnnual";

const UserPlan = () => {
	const { frequency } = useSelector((store: AppStore) => store.user.user!.account)

	return (
		frequency ?
			<>
				<div className="ca__userplan">
					<h4>Suscripción</h4>
					<div className="ca__userplan-basic">
						<PlanCard
							planName={frequency === "MONTHLY" ? "Mensual" : "Anual"}
							totalTokens={50000}
						/>
					</div>
				</div>

			</>
			: <></>
	);
};

export default UserPlan;
