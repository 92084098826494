import noFavoriteIcon from "@/assets/icons/likes.svg";
import MessageComponent from '@/components/MessageComponent/MessageComponent';
import { Spinner } from '@/components/Spinner';
import { useEditorComponent } from '@/hooks/useEditorComponent';
import { AppStore,IHistory } from '@/models';
import { useSelector } from 'react-redux';
import CopyCard from './CopyCard';


const Editor = () => {

    const { message, typeMessage, copy, copyInClipboard, handleOnChange, history, changeFavorite, deleteChoiceFunction, loadDelete,
        showFavorite, setShowFavorite
    } = useEditorComponent();
    const { currentContent, loading } = useSelector((store: AppStore) => store.projects);

    const favorites = () =>{
        var array: IHistory[] = []
        history.forEach((e)=> {
            if(e.favorite) array.push(e);
        });
        return array;
    }

    return (
        <>
            {
                message.length > 0 ? <MessageComponent title={message} type={typeMessage} />
                    : <></>
            }
            <div className={`ca__editor ${!currentContent ? "ca__editor-loading" : ""}`}>
                {
                    currentContent ?
                        <>
                            <div className="ca__editor-header">
                                <h4>{showFavorite ? "Mis Favoritos" : "Resultados"}</h4>
                                {/* <h4>Resultados</h4> */}
                                <div className={`ca__secondButton ${showFavorite ? "ca__editor-history-active" : ""}`} onClick={() => setShowFavorite((e) => !e)} >
                                    <i className="ri-heart-line"></i>
                                    Ver favoritos
                                </div>
                            </div>

                            {
                                showFavorite ? <>
                                    {
                                        favorites().length === 0 ?
                                        <div className="ca__editor-favorites-noData">
                                            <div>
                                                <img src={noFavoriteIcon} alt="No favorites" />
                                                <h4>No tienes favoritos</h4>
                                                <p>Marca un copy como favorito para poder acceder a él fácilmente en esta sección.</p>
                                            </div>
                                        </div> :
                                        favorites().map((h) => {
                                            return h.favorite ? <CopyCard onClick={() => changeFavorite(h.id, !h.favorite)} key={h.id} copy={h.result} copyInClipboard={copyInClipboard} handleOnChange={handleOnChange} isEditable={false} date={h.createdAt} isNewCopy={false} favorite={h.favorite} deleteFunction={() => deleteChoiceFunction(h.id)} /> : <></>
                                        })
                                    }
                                </>
                                    : <>
                                        <CopyCard copy={copy} copyInClipboard={copyInClipboard} handleOnChange={handleOnChange} date={currentContent.updatedAt} isNewCopy={true} />
                                        {
                                            history.length > 1 ?
                                                <>
                                                    <h4 className="ca__editor-versions">Versiones anteriores</h4>
                                                    {history.map((h, i) => {
                                                        return i < history.length - 1 ? <CopyCard onClick={() => changeFavorite(h.id, !h.favorite)} key={h.id} copy={h.result} copyInClipboard={copyInClipboard} handleOnChange={handleOnChange} isEditable={false} date={h.createdAt} isNewCopy={false} favorite={h.favorite} deleteFunction={() => deleteChoiceFunction(h.id)} loadDelete={loadDelete} /> : <></>

                                                    })}
                                                </> :
                                                <></>
                                        }
                                    </>
                            }

                        </>
                        : <>
                            {
                                loading ?
                                    <div className='ca__laodingEditor'>
                                        <div> <Spinner cssClass='ca__spinner-loader' />
                                            <p>¡Estamos creando el copy perfecto para ti!</p></div>
                                    </div> : <></>
                            }
                        </>
                }

            </div>
        </>
    )
}

export default Editor;