import iconNav from "@/assets/icons/ri_arrow-drop-left-line.svg";
import useHeader from "@/hooks/useHeader";



const Header = () => {
    const { backToProjects, isInProject, project, user,content } = useHeader();
    const { account: { totalWords } } = user!;

    return (
        <>
            <div className={`ca__header` }>
                <div className="ca__header-logo">
                    {
                        isInProject && project?.name ?
                            <div className="ca__singleProject-header">
                                <p className="ca__backtext" onClick={() => backToProjects()}>
                                    <img src={iconNav} alt="back" />
                                    Regresar al proyecto: {project.name}
                                </p>
                                <h4 className="ca__singleProject-title">{content?.name}</h4>
                            </div>
                            : <></>
                    }
                </div>
                <div className="ca__header-profile">
                    {
                        isInProject && project?.name ?
                            <div className="ca__header-profile-project">
                                <div>
                                    <div className="ca__header-tooltip">
                                        {totalWords?.toLocaleString('es-MX')}
                                    </div>
                                    <span>/50,000</span> </div>
                                palabras disponibles
                            </div>
                            : <></>
                    }

                </div>
            </div>

        </>
    )
}

export default Header;