// import { protectedRoutes } from "@/constants";
import { protectedRoutes } from "@/constants";
import { AppStore, Iuser, LocalStorageTypes } from "@/models";
import { logOut } from "@/redux/states";
import { LoginScreen, Proyects } from "@/screens";
import { ForgotPassword } from "@/screens/ForgotPassword";
import { RecoverPassword } from "@/screens/RecoverPassword";
import { deleteLocalStorage, getLocalStorage } from "@/utilities";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoute from "./PublicRoute";


function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop().split(';').shift();
}

export const AppRouter = () => {
  const stateUser = useSelector((store: AppStore) => store.user);
  const dispatch = useDispatch();

  const token = getCookie('token');

  const startLogout = () => dispatch(logOut());

  useEffect(() => {
    if (token === undefined && stateUser.user) {
      dispatch(logOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const infoStorage: Iuser | null = getLocalStorage(LocalStorageTypes.USER) ? JSON.parse(getLocalStorage(LocalStorageTypes.USER)!) : null;
    if (infoStorage) {
      if (!infoStorage.account.totalWords) {
        deleteLocalStorage(LocalStorageTypes.USER);
        startLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />} >
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="/forgot-password/:hash" element={<ForgotPassword />} />
          <Route path="*" element={<LoginScreen />} />
        </Route>
        <Route element={<ProtectedRoutes />} >
          {/* <Route path="/account" element={<Account />} />
          <Route path="/project/:hash" element={<SingleProject />} />
          <Route path="/" element={<Proyects />} /> */}
          {
            protectedRoutes.map((routes, i) => <Route key={i} path={routes.path} element={<routes.component />} />)
          }
          <Route path="*" element={<Proyects />} />
        </Route>
      </Routes>
    </ BrowserRouter >
  )
}
