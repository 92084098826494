import { useFiltersComponent } from "@/hooks/useFiltersComponent";
import { useProjects } from "@/hooks/useProjects";
import { AppStore } from "@/models";
import { useSelector } from "react-redux";
import { CreateProject, FiltersComponent, GridCardProject, HeaderProyects, Loader, NoProjects, TableHeader } from "./components";



const Proyects = () => {
    
    const { inputSearch, inputText, projectsFiltered } = useFiltersComponent();
    const { isLoad, changeCreateState, createproject } = useProjects();
    const { projects } = useSelector((e: AppStore) => e.projects);

    return (
        <>
            <div className="ca__proyects ca__second-container">
                <div className="ca__proyects-container">
                    {
                        !createproject ?
                            <>
                                <HeaderProyects changeCreateState={changeCreateState} />
                                {
                                    isLoad ?
                                        <Loader />
                                        : projects.length === 0 ?
                                            (<NoProjects changeCreateState={changeCreateState} />)
                                            : (
                                                <>
                                                    <FiltersComponent inputSearch={inputSearch} />
                                                    {
                                                        projects.length > 0 ? <TableHeader /> : <></>
                                                    }
                                                    <div className="ca__proyects-containerProjects ca__projects-list">
                                                        {
                                                            inputText.current.length > 0 && projectsFiltered.length === 0 ?
                                                                <h2 className="ca__text-nofiltered">No se encontró ningún proyecto </h2>
                                                                :
                                                                (projectsFiltered.length > 0 ? projectsFiltered : projects).map((project: any) => (
                                                                    <GridCardProject
                                                                        key={project.id}
                                                                        project={project}
                                                                    />
                                                                ))
                                                        }
                                                    </div>
                                                </>
                                            )
                                }
                            </>
                            : <CreateProject changeCreateState={changeCreateState} />
                    }
                </div>
            </div>
        </>
    )
}

export default Proyects;