import { configureStore } from "@reduxjs/toolkit";
import { projectSlice, templateSlice, userSlice } from "./states";


export default configureStore({
    reducer:{
        user: userSlice.reducer,
        projects: projectSlice.reducer,
        templates: templateSlice.reducer
    }
})