import { AppStore } from "@/models";
import { resetCurrentContent, resetCurrentTemplate } from "@/redux/states";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const [isInProject, setIsInProject] = useState(false);
    const {currentProject : project, currentContent: content } = useSelector((store: AppStore) => store.projects);
    const user = useSelector((store:AppStore)=> store.user.user);
    const token = useSelector((store: AppStore) => store.user.user?.accessToken);



    const backToProjects = () => {
        navigate(`/project/${project!.id}`);
        dispatch(resetCurrentTemplate());
        dispatch(resetCurrentContent());
    }


    useEffect(() => {
        if (!token) {
            navigate('/login', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])


    useEffect(() => {
        if (location.pathname.includes('project')) {
            setIsInProject(true);
        } else {
            setIsInProject(false);
        }
    }, [location.pathname]);

    return {
        isInProject,
        project,
        backToProjects,
        user,
        content
    }
}

export default useHeader;