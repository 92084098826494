import { protectedRoutes } from '@/constants/routesConstants';
import logo from "@/assets/logo/logo-sidebar.svg";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getTemplatesdb, logOut, resetCurrent, resetCurrentTemplate, resetCurrentContent } from '@/redux/states';
import { AppStore, ITemplate } from '@/models';
import { getProjectsFetch, getTemplateFetch } from '@/services';

const Badge = ({ fill, name }: any) => {

    var initials = name.split(" ").map(function (w: any) {
        return w[0]?.toUpperCase();
    }).concat();
    return (
        <div style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: fill, color: "#0B3B98", display: "flex", alignItems: 'center', justifyContent: 'center' }} >
            {initials}
        </div>
    )
}

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [path, setPath] = useState("");
    const [name, setName] = useState("");
    const [openSubMenu, setopenSubMenu] = useState(false)

    const user = useSelector((store: AppStore) => store.user.user);
    const accessToken = useSelector((store: AppStore) => store.user.user?.accessToken)
    const templates = useSelector((store: AppStore) => store.templates.templates);
    const projects = useSelector((store: AppStore) => store.projects.projects);

    const startGetTemplate = async () => {
        const templates: ITemplate | null = await getTemplateFetch(accessToken!)
        if (templates) {
            dispatch(getTemplatesdb(templates));
        }
    }

    const navigateClick = (path: string) => {
        resetStoreData()
        navigate(path)
    };

    const navigateToHome = () => {
        resetStoreData()
        navigate("/")
    }
    const resetStoreData = () => {
        dispatch(resetCurrent());
        dispatch(resetCurrentTemplate());
        dispatch(resetCurrentContent());
        setopenSubMenu(false);
    }

    const logOutUser = () => dispatch(logOut())

    const getName = () => {
        const names = user!.name.split(" ");
        if (names.length > 1) {
            setName(`${names[0]} ${names[1].charAt(0)}.`)
        } else {
            setName(names[0]);
        }
    }

    const startGetProjects = async () => {
        const resp = await getProjectsFetch(accessToken!);
        // ! Dispatch
        dispatch(getProjects(resp));
    }

    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (user?.name) getName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (accessToken && templates.length === 0) startGetTemplate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (location.pathname !== "/" && projects.length === 0)
            startGetProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="ca__sidebar">
            <div className="ca__sidebar-menu">
                <img src={logo} alt="CA" className='ca__sidebar-logo' onClick={navigateToHome} />
                {protectedRoutes.map((route) => route.showInMenu ? <div key={route.path} className={`ca__sidebar-item ${route.path === path ? "ca__item-active" : ""}`} onClick={() => navigateClick(route.path)} >
                    <p className="ca__sidebar-item-text">{route.name}</p>
                </div> : <div key={route.name}></div>)}
            </div>
            <div className="ca__sidebar-profile" >
                <div onClick={logOutUser} >Cerrar sesión</div>
                {/* <div className="ca__sidebar-profile-containerPhoto" >
                    {user?.name && (<Badge fill={"#D3E2FF"} name={user?.name} />)}
                    <p>{name}</p>
                </div>
                <i className='ri-arrow-right-s-fill'></i>
                {
                    openSubMenu ?
                        <div className="ca__header-submenu">
                            <div onClick={logOutUser} >Cerrar sesión</div>
                        </div> : <></>
                } */}
            </div>
        </div>
    )
}

export default Sidebar;