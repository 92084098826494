import { recoverPasswordHash } from '@/services';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export const useFormReset = (initialState: any) => {
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState<any>("error");
    const [values, setValues] = useState(initialState);

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const setMessageComponent = (title: string, type: string) => {
        setMessage(title);
        setType(type);
        setTimeout(() => {
            setMessage("");
        }, 3000)
    }
    const handleBlur = () => {
        if (values.password !== values.repassword) {
            setValues({
                ...values,
                error: "Las contraseñas no son iguales."
            })
        } else {
            setValues({
                ...values,
                error: ""
            })
        }
    }
    const onClickSend = async (hash: string) => {
        setIsLoad(true);
        if (values.error === "") {
            if (values.password.length >= 8) {
                if (values.password === values.repassword) {
                    const resp = await recoverPasswordHash(values.password, hash);
                    if (resp) {
                        setMessageComponent("Tu contraseña ha sido actualizada correctamente. Ya puedes iniciar sesión.", "done");
                        setTimeout(() => {
                            navigate('/login', { replace: true });
                        }, 4000)
                    } else {
                        setMessageComponent("No se pudo actualizar tu contraseña", "error");
                    }

                } else {
                    setMessageComponent("Las contraseñas no son iguales", "warning");
                }
            } else {
                setMessageComponent("La contraseña debe tener al menos 8 caracteres", "warning");
            }

        } else {
            setMessageComponent("Existen errores en el formulario.", "warning");
        }
        setIsLoad(false);

    }

    return { values, handleChange, onClickSend, handleBlur, isLoad, message, type };
}