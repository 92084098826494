import { filterCodeError } from "@/helpers/message.helpers";
import { AppStore, ModelLogin } from "@/models";
import { logIn } from "@/redux/states";
import { loginUser } from "@/services";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export const useLogin = (initialData: any) => {
    // const dispatch:any = useDispatch();
    const user = useSelector((state: AppStore) => state.user);
    const [values, setValues] = useState<ModelLogin>(initialData);
    const [fetchLogin, setFetchLogin] = useState(false);
    const [error, setError] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = target;
        setValues({
            ...values,
            [name]: value
        })
    }
    const onClickSend = async () => {
        setFetchLogin(true);
        const response = await loginUser(values);
        if(response.accessToken){
            dispatch(logIn(response));
            document.cookie = `token=${response.accessToken}; max-age=${response.expiresIn * 100}; path/; samesite=strict`
        }else{
            // !Show Error Message
            const message = filterCodeError(response);
            setError(message)
            setTimeout(()=>{
                setError("");
            }, 6000)
        }
        setFetchLogin(false);
        
    }

    useEffect(() => {
        if (user.user) {
            navigate('/', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user]);

    return { values, handleChange, onClickSend, fetchLogin,error };
}