import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageTypes } from "@/models/localstorage";
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from "@/utilities";
import { IStateUser } from "@/models";





const initialState: IStateUser = { user: getLocalStorage(LocalStorageTypes.USER) ? JSON.parse(getLocalStorage(LocalStorageTypes.USER)!) : null, fetchLogin: false, fetchRecover: false };


export const userSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        logIn: (state, action) => {
            setLocalStorage(LocalStorageTypes.USER, action.payload);
            return {
                ...state,
                user: action.payload
            };
        },
        updateUser: (state, action) => {
            return {
                ...state,
                user: action.payload
            }
        },
        updateInfoUser: (state, action) => {
            setLocalStorage(LocalStorageTypes.USER, {
                ...state.user,
                ...action.payload
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        },
        logOut: () => {
            deleteLocalStorage(LocalStorageTypes.USER);
            return {
                user: null,
                fetchLogin: false,
                fetchRecover: false
            }
        }
    }
})


export const {
    logIn,
    logOut,
    updateUser,
    updateInfoUser
} = userSlice.actions


export default userSlice.reducer