import { IElements, IStateProject } from "@/models";
import { createSlice } from "@reduxjs/toolkit";


const initialState: IStateProject = { currentProject: null, projects: [], currentContent: null, loading: false };

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        getProjects: (state, action) => {
            return {
                ...state,
                projects: action.payload
            }
        },
        selectProject: (state, action) => {
            return {
                ...state,
                currentProject: action.payload,
            };
        },
        createProject: (state, action) => {
            return {
                ...state,
                currentProject: action.payload,
                projects: [
                    ...state.projects,
                    action.payload
                ]
            }
        },

        resetCurrent: (state) => {
            return {
                ...state,
                currentProject: null
            }
        },
        resetCurrentContent: (state)=>{
            return{
                ...state,
                currentContent: null
            }
        },
        createElement: (state, action) => {
            return {
                ...state,
                currentProject: {
                    ...action.payload.project!,
                    elements: [
                        ...(action.payload.project!.elements ?? []),
                        action.payload.element
                    ]
                },
                currentContent: action.payload.element

            }
        },
        deleteProjectId: (state, action) => {
            return {
                ...state,
                projects: state.projects.filter((e) => e.id !== action.payload)
            }
        },
        deleteElement: (state, action) => {
            return {
                ...state,
                currentProject: {
                    ...state.currentProject!,
                    elements: state.currentProject!.elements.filter((e) => e.id !== action.payload)
                }
            }
        },
        patchElement: (state, action) => {
            const element = action.payload;
            let elements: IElements[] = state.currentProject?.elements ?? [];
            // const index = elements.findIndex((e)=> e.id === element.id);
            // elements[index] = element;
            return {
                ...state,
                currentProject: {
                    ...state.currentProject!,
                    elements: elements.map((e)=> {
                        if(e.id === element.id){
                            return element;
                        }
                        return e
                    }  )
                },
                currentContent: element
            }
        },
        selectCurrentElement : (state,action) =>{
            return{
                ...state,
                currentContent: action.payload
            }
        },
        changeLoading : (state,action)=>{
            return{
                ...state,
                loading: action.payload
            }
        }
    }
})


export const {
    selectProject,
    createProject,
    createElement,
    getProjects,
    deleteProjectId,
    deleteElement,
    patchElement,
    resetCurrent,
    selectCurrentElement,
    changeLoading,
    resetCurrentContent
} = projectSlice.actions


export default projectSlice.reducer