import { ICardTemplate } from "@/models";
import { selectCurrentTemplate } from "@/redux/states";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppStore } from '../../models/store';
import iconIan from "@/assets/icons/diamond.svg";



const CardTemplate = ({ template, index }: ICardTemplate) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {currentProject} = useSelector((e:AppStore)=> e.projects)

    const handleClick = () => {
        dispatch(selectCurrentTemplate(template));
        navigate(`/project/${currentProject?.id}/content`);
    }   

    return (
        <div className={`ca__cardtemplate ${index%3 === 0 ? "ca__cardtemplate-ultimate" : ""} `} onClick={handleClick}>
           {
            template.exclusive &&  <div className="ca__cardtemplate-ian">
            <img src={iconIan} alt="" />
            IAN
        </div>
           }
            {
                template.resources.icon ? <i className={template.resources.icon}></i>
                : <i className="ri-file-list-3-line"></i>
            }
            <h4>{template.name}</h4>
            <p>{template.description}</p>
        </div>
    )
}


export default CardTemplate;