import iconNav from "@/assets/icons/ri_arrow-drop-left-line.svg";
import { AppStore, IHeaderProject } from "@/models";
import { resetCurrent } from "@/redux/states";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const HeaderProyects = ({ changeCreateState }: IHeaderProject) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projects, currentProject } = useSelector((state: AppStore) => state.projects);

    // const resetSelectedProject = () => dispatch(resetCurrent());
    const resetSelectedProject = () => {
        dispatch(resetCurrent())
        navigate('/');
    };
    return (
        <div className="ca__headerProyects">
            <div>
                {
                    currentProject ?
                        <p className="ca__backtext" onClick={resetSelectedProject} ><img src={iconNav} alt="back" />Regresar a mis proyectos</p> : <></>
                }
                <h4>{currentProject ? currentProject.name : "Mis proyectos"}</h4>
            </div>
            {
                projects.length === 0 ? <></> :
                    <button className="ca__principalButton" onClick={changeCreateState}>
                        {
                            currentProject ? "Crear contenido" : "Crear proyecto"
                        }
                    </button>

            }
        </div>
    )
}

export default HeaderProyects;