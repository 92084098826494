import { IStateTemplate } from '@/models';
import { createSlice } from "@reduxjs/toolkit";



const initialState: IStateTemplate  = { 
    templates: [],
    currentTemplate: null
 };

export const templateSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        getTemplatesdb: (state, action) => {
            return {
                ...state,
                templates: action.payload
            }
        },
        selectCurrentTemplate: (state, action) =>{
            return{
                ...state,
                currentTemplate : action.payload
            }
        },
        resetCurrentTemplate: (state) =>{
            return{
                ...state,
                currentTemplate: null
            }
        }
    }
})


export const {
    getTemplatesdb,
    selectCurrentTemplate,
    resetCurrentTemplate
} = templateSlice.actions


export default templateSlice.reducer